import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { ShimmerThumbnail } from "react-shimmer-effects";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
const unidecode = require("unidecode");
function transformToSlug(inputString) {
  const slug = unidecode(inputString)
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return slug;
}
function Home() {
  const URlSERVER = "/";
  let images = [
    "./img/slides/1.jpg",
    "./img/slides/2.jpg",
    "./img/slides/3.jpg",
    "./img/slides/4.jpg",
  ];
  const [ArrayCategories, setArrayCategories] = useState([]);
  const [outProduct, setOutProduct] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [skip, setSkip] = useState(0);
  const [products, setProducts] = useState([]);
  const [shimmer, setShimmer] = useState(true);
  const [mayDoHuyetAp, setMayDoHuyetAp] = useState([]);
  const [mayXongKhiDung, setMayXongKhiDung] = useState([]);
  const getMoreProduct = () => {
    setLoadingProduct(true);
    setSkip(skip + 10);
    axios
      .get(`/api/products/get5product?skip=${skip}`)
      .then((response) => {
        const newProducts = response.data;
        if (Array.isArray(newProducts)) {
          setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        } else {
          setOutProduct(true);
        }
        setLoadingProduct(false);
      })
      .catch((error) => {
        setOutProduct(true);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          productsResponse,
          mayDoHuyetApResponse,
          mayXongKhiDungResponse,
          res,
        ] = await Promise.all([
          axios.get(`/api/products/get5product`),
          axios.get(`/api/products/getFollowCategory/may-do-huyet-ap`),
          axios.get(`/api/products/getFollowCategory/may-xong-khi-dung`),
          axios.get("/api/categories/getall"),
        ]);

        setProducts(productsResponse.data);
        setMayDoHuyetAp(mayDoHuyetApResponse.data.products);
        setMayXongKhiDung(mayXongKhiDungResponse.data.products);
        let categories = res.data.map((category) => ({
          _id: category._id,
          categoryID: category.categoryID,
          name: transformToSlug(category.name),
          fullName: category.name,
          products: null,
        }));
        const apiPromises = categories.map(async (category) => {
          try {
            const response = await axios.get(
              `api/products/getFollowCategory/${category.name}`
            );
            return response.data.products;
          } catch (error) {
            console.error(
              `Lỗi khi gửi yêu cầu API cho ${category.name}: ${error.message}`
            );
            return null;
          }
        });
        Promise.all(apiPromises)
          .then((listProducts) => {
            categories.forEach((category, index) => {
              category.products = listProducts[index];
            });

            categories = categories.filter(
              (category) => category.products.length >= 10
            );
            setArrayCategories(categories);
            setShimmer(false);
          })
          .catch((error) => {
            console.error("Lỗi khi gửi các yêu cầu API:", error);
          });
      } catch (error) {}
    };

    fetchData();
  }, []);
  return !shimmer ? (
    <>
      <Helmet>
        <title>
          Hệ Thống Dụng Cụ Y Khoa Lưu Gia | Chuyên Sỉ/Lẻ Thiết Bị Y Tế Chính
          Hãng
        </title>
      </Helmet>
      <div className="home-body">
        <div className="home-nav-guiders">
          <div className="home-nav-ul">
            {ArrayCategories.length > 0
              ? ArrayCategories.slice(0, 8).map((category) => (
                  <>
                    <Link
                      to={`/danh-muc/${category.name}`}
                      className="home-nav-li"
                    >
                      <p className="home-nav-li-text">{category.fullName}</p>
                    </Link>
                  </>
                ))
              : null}
            <Link to={`/danh-muc`} className="home-nav-li">
              <p className="home-nav-li-text">Xem tất cả</p>
            </Link>
          </div>
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="home-slides-image"
          >
            {images.map((src, i) => (
              <SwiperSlide>
                <img key={i} src={src} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="home-policies">
          <div className="home-policy">
            <div className="home-icon">
              <img
                src="./img/new-arrivals.png"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>
            <div className="home-text">
              <h4 className="home-header-text">Sản Phẩm Chính Hãng</h4>
              <p className="home-text-script">đa dạng và chuyên sâu</p>
            </div>
          </div>
          <div className="home-policy">
            <div className="home-icon">
              <img
                src="./img/box.png"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>
            <div className="home-text">
              <h4 className="home-header-text">Đổi trả trong 7 ngày</h4>
              <p className="home-text-script">kể từ ngày mua hàng</p>
            </div>
          </div>
          <div className="home-policy">
            <div className="home-icon">
              <img
                src="./img/like.png"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>
            <div className="home-text">
              <h4 className="home-header-text">Cam kết 100%</h4>
              <p className="home-text-script">chất lượng sản phẩm</p>
            </div>
          </div>
          <div className="home-policy">
            <div className="home-icon">
              <img
                src="./img/phone.png"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
            </div>
            <div className="home-text">
              <h4 className="home-header-text">Tư vấn nhanh chóng</h4>
              <p className="home-text-script">tận tình chuyên nghiệp</p>
            </div>
          </div>
        </div>
        <div className="home-header-text-category">
          <p>Danh mục</p>
        </div>
        <div className="home-header-text-category">
          <h1>Danh mục nổi bật</h1>
        </div>
        <div className="flex-center">
          <div className="home-grid-category">
            <Link to="/danh-muc/may-do-huyet-ap">
              <div className="home-category">
                <img src="./img/maydohuyetap.jpg" alt="" />
                <p className="home-text">Máy đo huyết áp</p>
              </div>
            </Link>
            <Link to="/danh-muc/may-xong-khi-dung">
              <div className="home-category">
                <img src="./img/mayxongkhidung.jpg" alt="" />
                <p className="home-text">Máy xong khí dung</p>
              </div>
            </Link>
            <Link to="/danh-muc/nem-y-khoa">
              <div className="home-category">
                <img src="./img/nemykhoa.jpg" alt="" />
                <p className="home-text">Nệm y khoa</p>
              </div>
            </Link>
            <Link to="/danh-muc/nem-chong-loet">
              <div className="home-category">
                <img src="./img/nemchongloet.jpg" alt="" />
                <p className="home-text">Nệm chống loét</p>
              </div>
            </Link>
            <Link to="/danh-muc/may-do-duong-huyet">
              <div className="home-category">
                <img src="./img/maydoduonghuyet.jpg" alt="" />
                <p className="home-text">Máy đo đường huyết</p>
              </div>
            </Link>
            <Link to="/danh-muc/que-duong-huyet">
              <div className="home-category">
                <img src="./img/queduonghuyet.jpg" alt="" />
                <p className="home-text">Que đường huyết</p>
              </div>
            </Link>
            <Link to="/danh-muc/nhiet-am-ke">
              <div className="home-category">
                <img src="./img/nhietamke.jpg" alt="" />
                <p className="home-text">Nhiệt ẩm kế</p>
              </div>
            </Link>
            <Link to="/danh-muc/nhiet-ke">
              <div className="home-category">
                <img src="./img/nhietke.jpg" alt="" />
                <p className="home-text">Nhiệt kế</p>
              </div>
            </Link>
            <Link to="/danh-muc/ao-blouse">
              <div className="home-category">
                <img src="./img/aoblouse.jpg" alt="" />
                <p className="home-text">Áo Blouse</p>
              </div>
            </Link>
            <Link to="/danh-muc/dien-chuan-massage">
              <div className="home-category">
                <img src="./img/dienchanmassage.jpg" alt="" />
                <p className="home-text">Diện chuẩn massage</p>
              </div>
            </Link>
            <Link to="/danh-muc/bon-ngam-massage">
              <div className="home-category">
                <img src="./img/bonngammassage.jpg" alt="" />
                <p className="home-text">Bồn ngâm massage</p>
              </div>
            </Link>
            <Link to="/danh-muc/may-massage">
              <div className="home-category">
                <img src="./img/maymassage.jpg" alt="" />
                <p className="home-text">Máy massage</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="home-product-head-bar">
          <h3 className="home-header">Máy Đo Huyết Áp | Máy Đo Huyết Áp Cơ</h3>
          <Link to="/danh-muc/may-do-huyet-ap" className="home-redirect">
            Xem tất cả
            <img
              src="./img/right-arrows.png"
              alt=""
              style={{ width: "30px", height: "30px" }}
            />
          </Link>
        </div>
        <div className="flex-center">
          <div className="home-row-product">
            {mayDoHuyetAp.map((product, index) => (
              <Link
                to={
                  "/chi-tiet-san-pham/" + transformToSlug(product.productName)
                }
                id="redirect-product1"
              >
                <div className="home-product">
                  {product.sellingPrice !== product.originalPrice
                    ? (() => {
                        const originalPrice = product.originalPrice;
                        const sellingPrice = product.sellingPrice;
                        const discountValue = originalPrice - sellingPrice;
                        const discountPercent =
                          (discountValue / originalPrice) * 100;
                        return (
                          <div className="home-discount">
                            <div className="home-clos">
                              {discountPercent.toFixed(0)}%
                            </div>
                            <div className="home-col-d">
                              <div className="home-left" />
                              <div className="home-right" />
                            </div>
                          </div>
                        );
                      })()
                    : null}

                  <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                  <h5 className="home-name-product">{product.productName}</h5>
                  <div className="home-price">
                    {product ? (
                      product.quantity === 0 ? (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      ) : !product.contactToPurchase ? (
                        <div className="dt-prd-row-price">
                          {product.sellingPrice !== product.originalPrice ? (
                            <>
                              <p className="home-origin-price">
                                {product.originalPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      )
                    ) : null}
                  </div>
                  <div className="home-star">
                    {Array.from({ length: 5 }, (_, index) =>
                      index < product.averageRating ? (
                        <span className="fa fa-star checked" />
                      ) : (
                        <span className="fa fa-star" />
                      )
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="home-product-head-bar">
          <h3 className="home-header">Máy Xong Khí Dung</h3>
          <Link to="/danh-muc/may-xong-khi-dung" className="home-redirect">
            Xem tất cả
            <img
              src="./img/right-arrows.png"
              alt=""
              style={{ width: "30px", height: "30px" }}
            />
          </Link>
        </div>
        <div className="flex-center">
          <div className="home-row-product">
            {mayXongKhiDung.map((product, index) => (
              <Link
                to={
                  "/chi-tiet-san-pham/" + transformToSlug(product.productName)
                }
                id="redirect-product1"
              >
                <div className="home-product">
                  {product.sellingPrice !== product.originalPrice
                    ? (() => {
                        const originalPrice = product.originalPrice;
                        const sellingPrice = product.sellingPrice;
                        const discountValue = originalPrice - sellingPrice;
                        const discountPercent =
                          (discountValue / originalPrice) * 100;
                        return (
                          <div className="home-discount">
                            <div className="home-clos">
                              {discountPercent.toFixed(0)}%
                            </div>
                            <div className="home-col-d">
                              <div className="home-left" />
                              <div className="home-right" />
                            </div>
                          </div>
                        );
                      })()
                    : null}

                  <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                  <h5 className="home-name-product">{product.productName}</h5>
                  <div className="home-price">
                    {product ? (
                      product.quantity === 0 ? (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      ) : !product.contactToPurchase ? (
                        <div className="dt-prd-row-price">
                          {product.sellingPrice !== product.originalPrice ? (
                            <>
                              <p className="home-origin-price">
                                {product.originalPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      )
                    ) : null}
                  </div>
                  <div className="home-star">
                    {Array.from({ length: 5 }, (_, index) =>
                      index < product.averageRating ? (
                        <span className="fa fa-star checked" />
                      ) : (
                        <span className="fa fa-star" />
                      )
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div
          className="home-header-text-category"
          style={{ color: "#1351dc", marginTop: "50px" }}
        >
          <p>SẢN PHẨM</p>
        </div>
        <div className="home-header-text-category">
          <h1 style={{ color: "black" }}>Gợi ý hôm nay</h1>
        </div>
        {/* <div
          className="home-product-head-bar"
          style={{ justifyContent: "end" }}
        >
          <Link href className="home-redirect">
            Xem tất cả
            <img
              src="./img/right-arrows.png"
              alt=""
              style={{ width: "30px", height: "30px" }}
            />
          </Link>
        </div> */}
        <div className="flex-center">
          <div className="home-row-product">
            {products.map((product, index) => (
              <Link
                to={
                  "/chi-tiet-san-pham/" + transformToSlug(product.productName)
                }
                id="redirect-product1"
              >
                <div className="home-product">
                  {product.sellingPrice !== product.originalPrice
                    ? (() => {
                        const originalPrice = product.originalPrice;
                        const sellingPrice = product.sellingPrice;
                        const discountValue = originalPrice - sellingPrice;
                        const discountPercent =
                          (discountValue / originalPrice) * 100;
                        return (
                          <div className="home-discount">
                            <div className="home-clos">
                              {discountPercent.toFixed(0)}%
                            </div>
                            <div className="home-col-d">
                              <div className="home-left" />
                              <div className="home-right" />
                            </div>
                          </div>
                        );
                      })()
                    : null}

                  <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                  <h5 className="home-name-product">{product.productName}</h5>
                  <div className="home-price">
                    {product ? (
                      product.quantity === 0 ? (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      ) : !product.contactToPurchase ? (
                        <div className="dt-prd-row-price">
                          {product.sellingPrice !== product.originalPrice ? (
                            <>
                              <p className="home-origin-price">
                                {product.originalPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      )
                    ) : null}
                  </div>
                  <div className="home-star">
                    {Array.from({ length: 5 }, (_, index) =>
                      index < product.averageRating ? (
                        <span className="fa fa-star checked" />
                      ) : (
                        <span className="fa fa-star" />
                      )
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {!outProduct ? (
          <>
            {loadingProduct ? (
              <div className="home-load-more-product">
                <div className="home-loading">
                  <div className="home-dot"></div>
                  <div className="home-dot"></div>
                  <div className="home-dot"></div>
                </div>
              </div>
            ) : (
              <div onClick={getMoreProduct} className="home-load-more-product">
                <img
                  src="/img/load-more.png"
                  alt=""
                  style={{ width: "10px", height: "10px" }}
                />
                <p>Xem thêm sản phẩm</p>
              </div>
            )}
          </>
        ) : null}

        {/* <div className="home-row-for-sale">
          <div className="home-best-product">
            <p className="home-header">Giảm giá sốc</p>
            <div className="home-column-product">
              {products.slice(0, 3).map((product, index) => (
                <Link
                  key={index}
                  to={"/chi-tiet-san-pham/" + transformToSlug(product.productName)}
                  id="redirect-product"
                >
                  <div className="home-product1">
                    <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                    <div className="home-column-infor">
                      <p className="home-name-product">{product.productName}</p>
                      <div className="home-price">
                        {product.sellingPrice !== product.originalPrice ? (
                          <>
                            <p className="home-origin-price">
                              {product.originalPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="home-star">
                        {Array.from({ length: 5 }, (v, i) => (
                          <span
                            key={i}
                            className={`fa fa-star fa-2xs ${
                              i < 3 ? "checked" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="home-best-product">
            <p className="home-header">Top bán chạy</p>
            <div className="home-column-product">
              {products.slice(0, 3).map((product, index) => (
                <Link
                  key={index}
                  to={"/chi-tiet-san-pham/" + transformToSlug(product.productName)}
                  id="redirect-product"
                >
                  <div className="home-product1">
                    <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                    <div className="home-column-infor">
                      <p className="home-name-product">{product.productName}</p>
                      <div className="home-price">
                        {product.sellingPrice !== product.originalPrice ? (
                          <>
                            <p className="home-origin-price">
                              {product.originalPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="home-star">
                        {Array.from({ length: 5 }, (v, i) => (
                          <span
                            key={i}
                            className={`fa fa-star fa-2xs ${
                              i < 3 ? "checked" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="home-best-product">
            <p className="home-header">Sản phẩm được yêu thích nhất</p>
            <div className="home-column-product">
              {products.slice(0, 3).map((product, index) => (
                <Link
                  key={index}
                  to={"/chi-tiet-san-pham/" + transformToSlug(product.productName)}
                  id="redirect-product"
                >
                  <div className="home-product1">
                    <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                    <div className="home-column-infor">
                      <p className="home-name-product">{product.productName}</p>
                      <div className="home-price">
                        {product.sellingPrice !== product.originalPrice ? (
                          <>
                            <p className="home-origin-price">
                              {product.originalPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        )}
                      </div>
                      <div className="home-star">
                        {Array.from({ length: 5 }, (v, i) => (
                          <span
                            key={i}
                            className={`fa fa-star fa-2xs ${
                              i < 3 ? "checked" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div> */}
        <div className="home-trailer">
          <h1>HỆ THỐNG PHÂN PHỐI DỤNG CỤ Y TẾ CHÍNH HÃNG</h1>
          <h1>LƯU GIA</h1>
        </div>
        <div className="home-header-brain">Thương hiệu nổi bật</div>
        <div className="home-brain-icon">
          <img
            src="./img/brand_icon/omron.png"
            alt=""
            className="home-img-brand-icon"
          />
          <img
            src="./img/brand_icon/beurer.png"
            alt=""
            className="home-img-brand-icon"
          />
          <img
            src="./img/brand_icon/microlife.png"
            alt=""
            className="home-img-brand-icon"
          />
          <img
            src="./img/brand_icon/omron.png"
            alt=""
            className="home-img-brand-icon"
          />
        </div>
      </div>
      <div className="home-body-phone">
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="home-carousel"
        >
          {images.map((src, i) => (
            <SwiperSlide>
              <img key={i} src={src} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="home-black-category">DANH MỤC</div>
        <div className="home-main-color-category">Danh mục nổi bật</div>
        <Swiper
          slidesPerView={3}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          spaceBetween={0}
          modules={[Autoplay]}
          className="home-slide-phone-categories"
        >
          <SwiperSlide>
            <Link
              to="/danh-muc/may-do-huyet-ap"
              className="home-silde-phone-category"
            >
              <img src="./img/maydohuyetap.jpg" alt="" />
              <p className="home-text">Máy đo huyết áp</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/may-xong-khi-dung"
              className="home-silde-phone-category"
            >
              <img src="./img/mayxongkhidung.jpg" alt="" />
              <p className="home-text">Máy xong khí dung</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/nem-y-khoa"
              className="home-silde-phone-category"
            >
              <img src="./img/nemykhoa.jpg" alt="" />
              <p className="home-text">Nệm y khoa</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/nem-chong-loet"
              className="home-silde-phone-category"
            >
              <img src="./img/nemchongloet.jpg" alt="" />
              <p className="home-text">Nệm chống loét</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/may-do-duong-huyet"
              className="home-silde-phone-category"
            >
              <img src="./img/maydoduonghuyet.jpg" alt="" />
              <p className="home-text">Máy đo đường huyết</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/que-duong-huyet"
              className="home-silde-phone-category"
            >
              <img src="./img/queduonghuyet.jpg" alt="" />
              <p className="home-text">Que đường huyết</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/nhiet-am-ke"
              className="home-silde-phone-category"
            >
              <img src="./img/nhietamke.jpg" alt="" />
              <p className="home-text">Nhiệt ẩm kế</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/danh-muc/nhiet-ke" className="home-silde-phone-category">
              <img src="./img/nhietke.jpg" alt="" />
              <p className="home-text">Nhiệt kế</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/ao-blouse"
              className="home-silde-phone-category"
            >
              <img src="./img/aoblouse.jpg" alt="" />
              <p className="home-text">Áo Blouse</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/dien-chuan-massage"
              className="home-silde-phone-category"
            >
              <img src="./img/dienchanmassage.jpg" alt="" />
              <p className="home-text">Diện chuẩn massage</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/bon-ngam-massage"
              className="home-silde-phone-category"
            >
              <img src="./img/bonngammassage.jpg" alt="" />
              <p className="home-text">Bồn ngâm massage</p>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link
              to="/danh-muc/may-massage"
              className="home-silde-phone-category"
            >
              <img src="./img/maymassage.jpg" alt="" />
              <p className="home-text">Máy massage</p>
            </Link>
          </SwiperSlide>
        </Swiper>
        <div className="home-header-main-phone">
          <p className="home-title-header-main-phone">Máy Đo Huyết Áp</p>
          <p className="home-link-to-detail">Xem tất cả {"►"}</p>
        </div>
        <Swiper
          slidesPerView={2}
          spaceBetween={0}
          className="home-row-products-phone"
        >
          {mayDoHuyetAp.map((product, index) => (
            <SwiperSlide>
              <Link
                to={
                  "/chi-tiet-san-pham/" + transformToSlug(product.productName)
                }
                id="redirect-product-phone"
              >
                <div className="home-product">
                  {product.sellingPrice !== product.originalPrice
                    ? (() => {
                        const originalPrice = product.originalPrice;
                        const sellingPrice = product.sellingPrice;
                        const discountValue = originalPrice - sellingPrice;
                        const discountPercent =
                          (discountValue / originalPrice) * 100;
                        return (
                          <div className="home-discount">
                            <div className="home-clos">
                              {discountPercent.toFixed(0)}%
                            </div>
                            <div className="home-col-d">
                              <div className="home-left" />
                              <div className="home-right" />
                            </div>
                          </div>
                        );
                      })()
                    : null}

                  <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                  <h5 className="home-name-product">{product.productName}</h5>
                  <div className="home-price">
                    {product ? (
                      product.quantity === 0 ? (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      ) : !product.contactToPurchase ? (
                        <div className="dt-prd-row-price">
                          {product.sellingPrice !== product.originalPrice ? (
                            <>
                              <p className="home-origin-price">
                                {product.originalPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      )
                    ) : null}
                  </div>
                  <div className="home-star">
                    {Array.from({ length: 5 }, (_, index) =>
                      index < product.averageRating ? (
                        <span className="fa fa-star checked" />
                      ) : (
                        <span className="fa fa-star" />
                      )
                    )}
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="home-header-main-phone">
          <p className="home-title-header-main-phone">Máy Xong Khí Dung</p>
          <p className="home-link-to-detail">Xem tất cả {"►"}</p>
        </div>
        <Swiper
          slidesPerView={2}
          spaceBetween={0}
          className="home-row-products-phone"
        >
          {mayXongKhiDung.map((product, index) => (
            <SwiperSlide>
              <Link
                to={
                  "/chi-tiet-san-pham/" + transformToSlug(product.productName)
                }
                id="redirect-product-phone"
              >
                <div className="home-product">
                  {product.sellingPrice !== product.originalPrice
                    ? (() => {
                        const originalPrice = product.originalPrice;
                        const sellingPrice = product.sellingPrice;
                        const discountValue = originalPrice - sellingPrice;
                        const discountPercent =
                          (discountValue / originalPrice) * 100;
                        return (
                          <div className="home-discount">
                            <div className="home-clos">
                              {discountPercent.toFixed(0)}%
                            </div>
                            <div className="home-col-d">
                              <div className="home-left" />
                              <div className="home-right" />
                            </div>
                          </div>
                        );
                      })()
                    : null}

                  <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                  <h5 className="home-name-product">{product.productName}</h5>
                  <div className="home-price">
                    {product ? (
                      product.quantity === 0 ? (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      ) : !product.contactToPurchase ? (
                        <div className="dt-prd-row-price">
                          {product.sellingPrice !== product.originalPrice ? (
                            <>
                              <p className="home-origin-price">
                                {product.originalPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="dt-prd-row-price">
                          <p
                            className="dt-prd-price"
                            style={{
                              color: "#E10000",
                              fontFamily: "'Be Vietnam Pro', sans-serif",
                            }}
                          >
                            Liên Hệ
                          </p>
                        </div>
                      )
                    ) : null}
                  </div>
                  <div className="home-star">
                    {Array.from({ length: 5 }, (_, index) =>
                      index < product.averageRating ? (
                        <span className="fa fa-star checked" />
                      ) : (
                        <span className="fa fa-star" />
                      )
                    )}
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="home-black-category">SẢN PHẨM</div>
        <div className="home-main-color-category">Gợi ý hôm nay</div>
        <div className="home-row-product-phone">
          {products.map((product, index) => (
            <Link
              to={"/chi-tiet-san-pham/" + transformToSlug(product.productName)}
              id="redirect-product-phone-1"
            >
              <div className="home-product-1">
                {product.sellingPrice !== product.originalPrice
                  ? (() => {
                      const originalPrice = product.originalPrice;
                      const sellingPrice = product.sellingPrice;
                      const discountValue = originalPrice - sellingPrice;
                      const discountPercent =
                        (discountValue / originalPrice) * 100;
                      return (
                        <div className="home-discount">
                          <div className="home-clos">
                            {discountPercent.toFixed(0)}%
                          </div>
                          <div className="home-col-d">
                            <div className="home-left" />
                            <div className="home-right" />
                          </div>
                        </div>
                      );
                    })()
                  : null}

                <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                <h5 className="home-name-product">{product.productName}</h5>
                <div className="home-price">
                  {product ? (
                    product.quantity === 0 ? (
                      <div className="dt-prd-row-price">
                        <p
                          className="dt-prd-price"
                          style={{
                            color: "#E10000",
                            fontFamily: "'Be Vietnam Pro', sans-serif",
                            fontWeight: "bold",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          Liên Hệ
                        </p>
                      </div>
                    ) : !product.contactToPurchase ? (
                      <div className="dt-prd-row-price">
                        {product.sellingPrice !== product.originalPrice ? (
                          <>
                            <p className="home-origin-price">
                              {product.originalPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="home-offcial-price">
                              {product.sellingPrice.toLocaleString("vi-VN", {
                                style: "currency",
                                currency: "VND",
                              })}
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="dt-prd-row-price">
                        <p
                          className="dt-prd-price"
                          style={{
                            color: "#E10000",
                            fontFamily: "'Be Vietnam Pro', sans-serif",
                          }}
                        >
                          Liên Hệ
                        </p>
                      </div>
                    )
                  ) : null}
                </div>
                <div className="home-star">
                  {Array.from({ length: 5 }, (_, index) =>
                    index < product.averageRating ? (
                      <span className="fa fa-star checked" />
                    ) : (
                      <span className="fa fa-star" />
                    )
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>
        {!outProduct ? (
          <>
            {loadingProduct ? (
              <div className="home-load-more-product-phone">
                <div className="home-loading">
                  <div className="home-dot"></div>
                  <div className="home-dot"></div>
                  <div className="home-dot"></div>
                </div>
              </div>
            ) : (
              <div
                onClick={getMoreProduct}
                className="home-load-more-product-phone"
              >
                <img
                  src="/img/load-more.png"
                  alt=""
                  style={{ width: "10px", height: "10px" }}
                />
                <p>Xem thêm sản phẩm</p>
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Hệ Thống Dụng Cụ Y Khoa Lưu Gia</title>
      </Helmet>
      <div className="shimmer-body">
        <div className="shimmer-nav-guiders">
          <div style={{ width: "20vw" }}>
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
            <ShimmerThumbnail className={"shimmer-categories"} height={45} />
          </div>
          <ShimmerThumbnail height={440} className="shimmer-slides-image" />
        </div>
        <div className="shimmer-policies">
          <ShimmerThumbnail height={60} className="shimmer-policy" />
          <ShimmerThumbnail height={60} className="shimmer-policy" />
          <ShimmerThumbnail height={60} className="shimmer-policy" />
          <ShimmerThumbnail height={60} className="shimmer-policy" />
        </div>
      </div>
      <div className="shimmer-body-phone">
        <ShimmerThumbnail className="slides-images" />
        <ShimmerThumbnail className="title" height={25} rounded />
        <div className="shimmer-categories">
          <ShimmerThumbnail height={70} className="shimmer-category" />
          <ShimmerThumbnail height={70} className="shimmer-category" />
          <ShimmerThumbnail height={70} className="shimmer-category" />
          <ShimmerThumbnail height={70} className="shimmer-category" />
        </div>
        <ShimmerThumbnail className="title-prd" height={25} rounded />
        <div className="shimmer-categories">
          <ShimmerThumbnail height={250} className="shimmer-category-prd" />
          <ShimmerThumbnail height={250} className="shimmer-category-prd" />
        </div>
      </div>
    </>
  );
}

export default Home;
