import React from "react";
import { Helmet } from "react-helmet";

function PageNotFound() {
  return (
    <>
      <Helmet>
        <title>Không tìm thấy trang</title>
      </Helmet>
      <div className="page-not-found">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/img/search.png"
            alt=""
            style={{ width: 300, height: 300 }}
          />
          <h3
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#4a4f63",
            }}
          >
            Không tìm thấy trang
            <span
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#4a4f63",
                marginLeft: "5px",
              }}
            >
              "{window.location.pathname}"
            </span>
          </h3>
          <a href="/" className="return-home">
            Quay về trang chủ
          </a>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
