import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

function Register() {
  const nav = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [validateFullName, setValidateFullName] = useState(null);
  const [validateEmail, setValidateEmail] = useState(null);
  const [validatePhone, setValidatePhone] = useState(null);
  const [validatePassword, setValidatePassword] = useState(null);
  const [validateRePassword, setValidateRePassword] = useState(null);
  const [rePasswordType, setRePasswordType] = useState("password");
  const changeStateRePasswordType = () => {
    rePasswordType === "password"
      ? setRePasswordType("text")
      : setRePasswordType("password");
  };
  const [PasswordType, setPasswordType] = useState("password");
  const changeStatePasswordType = () => {
    PasswordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };
  function isValidEmail(email) {
    // Biểu thức chính quy kiểm tra cấu trúc email
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }
  const handleRegistration = async () => {
    // Kiểm tra và hiển thị thông báo lỗi nếu cần
    // Kiểm tra và hiển thị thông báo lỗi nếu cần
    if (
      !fullName ||
      !email ||
      !phoneNumber ||
      !password ||
      password.length < 8 ||
      !isValidEmail(email)
    ) {
      if (!fullName) {
        setValidateFullName("Vui lòng nhập họ và tên.");
        setTimeout(() => setValidateFullName(null), 2000);
      } else {
        setValidateFullName(null);
      }

      if (!email) {
        setValidateEmail("Vui lòng nhập địa chỉ email.");
        setTimeout(() => setValidateEmail(null), 2000);
      } else if (!isValidEmail(email)) {
        setValidateEmail("Địa chỉ email không hợp lệ.");
        setTimeout(() => setValidateEmail(null), 2000);
      } else {
        setValidateEmail(null);
      }
      if (!phoneNumber) {
        setValidatePhone("Vui lòng nhập số điện thoại.");
        setTimeout(() => setValidatePhone(null), 2000);
      } else if (!/^(0|\+84)\d{9,10}$/.test(phoneNumber)) {
        setValidatePhone("Số điện thoại không hợp lệ.");
        setTimeout(() => setValidatePhone(null), 2000);
      } else {
        setValidatePhone(null);
      }

      if (!password) {
        setValidatePassword("Vui lòng nhập mật khẩu.");
        setTimeout(() => setValidatePassword(null), 2000);
      } else if (password.length < 8) {
        setValidatePassword("Mật khẩu phải trên 8 ký tự.");
        setTimeout(() => setValidatePassword(null), 2000);
      } else {
        setValidatePassword(null);
      }
      return;
    }
    if (rePassword !== password) {
      setValidateRePassword("Mật khẩu nhập lại không khớp.");
      setTimeout(() => setValidateRePassword(null), 2000);
      return;
    } else {
      setValidateRePassword(null);
    }
    setLoading(true);
    try {
      const response = await axios.post("/api/users/register", {
        fullName,
        email,
        phoneNumber,
        password,
      });
      if (response.status === 201) {
        nav("/dang-nhap", { state: { email, password, successMessage: true } });
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setValidateEmail("Email đã được sử dụng.");
        setTimeout(() => setValidateEmail(null), 2000);
      }
      setLoading(false);
    }
  };
  const token = Cookies.get("accessToken");
  useEffect(() => {
    if (token) {
      nav("/");
      window.location.reload();
    }
  }, []);
  return (
    <>
      <div
        className="rgt-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="rgt-frame-login">
        <Helmet>
          <title>Đăng Ký</title>
        </Helmet>
        <div className="rgt-login">
          <p className="rgt-text">Đăng Ký</p>
          <div className="rgt-frm">
            <input
              type="text"
              name="fullname"
              id="rgt-fullname"
              placeholder="Họ Và Tên"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            {validateFullName && (
              <div className="rgt-error">{validateFullName}</div>
            )}
          </div>
          <div className="rgt-frm">
            <input
              type="text"
              name="email"
              id="rgt-email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {validateEmail && <div className="rgt-error">{validateEmail}</div>}
          </div>
          <div className="rgt-frm">
            <input
              type="text"
              name="phone"
              id="rgt-phone"
              placeholder="Số Điện Thoại"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {validatePhone && <div className="rgt-error">{validatePhone}</div>}
          </div>
          <div className="rgt-frm">
            <div className="rgt-password">
              <input
                type={PasswordType}
                name="password"
                id="rgt-password1"
                placeholder="Mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {PasswordType === "password" ? (
                <i
                  onClick={changeStatePasswordType}
                  className="fa-regular fa-eye-slash eye"
                  id="rgt-hide-password2"
                  style={{ color: "#827a7a" }}
                />
              ) : (
                <i
                  onClick={changeStatePasswordType}
                  className="fa-regular fa-eye eye"
                  id="rgt-show-password2"
                  style={{ color: "#827a7a" }}
                />
              )}
            </div>
            {validatePassword && (
              <div className="rgt-error">{validatePassword}</div>
            )}
          </div>

          <div className="rgt-frm">
            <div className="rgt-repassword">
              <input
                type={rePasswordType}
                name="password"
                id="rgt-password2"
                placeholder="Nhập lại mật khẩu"
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
              />
              {rePasswordType === "password" ? (
                <i
                  onClick={changeStateRePasswordType}
                  className="fa-regular fa-eye-slash eye"
                  id="rgt-hide-password2"
                  style={{ color: "#827a7a" }}
                />
              ) : (
                <i
                  onClick={changeStateRePasswordType}
                  className="fa-regular fa-eye eye"
                  id="rgt-show-password2"
                  style={{ color: "#827a7a" }}
                />
              )}
            </div>
            {validateRePassword && (
              <div className="rgt-error">{validateRePassword}</div>
            )}
          </div>
          <button onClick={handleRegistration} type="submit">
            Đăng Ký
          </button>
          <div className="rgt-center">
            <p>Đã có tài khoản?</p>
            <Link to="/dang-nhap" style={{ marginLeft: "5px" }}>
              Đăng nhập
            </Link>
          </div>
          {/* <div className="rgt-center">
            <p>Hoặc đăng nhập bằng:</p>
          </div>
          <div className="rgt-center">
            <Link href>
              <img src="./img/icon_social/facebook.png" alt="" />
            </Link>
            <Link href>
              <img src="./img/icon_social/google.png" alt="" />
            </Link>
          </div> */}
        </div>
      </div>
      <div className="rgt-frame-login-phone">
        <Helmet>
          <title>Đăng Ký</title>
        </Helmet>
        <div className="rgt-login">
          <p className="rgt-text">Đăng Ký</p>
          <div className="rgt-frm">
            <input
              type="text"
              name="fullname"
              id="rgt-fullname"
              placeholder="Họ Và Tên"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            {validateFullName && (
              <div className="rgt-error">{validateFullName}</div>
            )}
          </div>
          <div className="rgt-frm">
            <input
              type="text"
              name="email"
              id="rgt-email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {validateEmail && <div className="rgt-error">{validateEmail}</div>}
          </div>
          <div className="rgt-frm">
            <input
              type="text"
              name="phone"
              id="rgt-phone"
              placeholder="Số Điện Thoại"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {validatePhone && <div className="rgt-error">{validatePhone}</div>}
          </div>
          <div className="rgt-frm">
            <div className="rgt-password">
              <input
                type={PasswordType}
                name="password"
                id="rgt-password1"
                placeholder="Mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {PasswordType === "password" ? (
                <i
                  onClick={changeStatePasswordType}
                  className="fa-regular fa-eye-slash eye"
                  id="rgt-hide-password2"
                  style={{ color: "#827a7a" }}
                />
              ) : (
                <i
                  onClick={changeStatePasswordType}
                  className="fa-regular fa-eye eye"
                  id="rgt-show-password2"
                  style={{ color: "#827a7a" }}
                />
              )}
            </div>
            {validatePassword && (
              <div className="rgt-error">{validatePassword}</div>
            )}
          </div>

          <div className="rgt-frm">
            <div className="rgt-repassword">
              <input
                type={rePasswordType}
                name="password"
                id="rgt-password2"
                placeholder="Nhập lại mật khẩu"
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
              />
              {rePasswordType === "password" ? (
                <i
                  onClick={changeStateRePasswordType}
                  className="fa-regular fa-eye-slash eye"
                  id="rgt-hide-password2"
                  style={{ color: "#827a7a" }}
                />
              ) : (
                <i
                  onClick={changeStateRePasswordType}
                  className="fa-regular fa-eye eye"
                  id="rgt-show-password2"
                  style={{ color: "#827a7a" }}
                />
              )}
            </div>
            {validateRePassword && (
              <div className="rgt-error">{validateRePassword}</div>
            )}
          </div>
          <button onClick={handleRegistration} type="submit">
            Đăng Ký
          </button>
          <div className="rgt-center">
            <p>Đã có tài khoản?</p>
            <Link to="/dang-nhap" style={{ marginLeft: "5px" }}>
              Đăng nhập
            </Link>
          </div>
          {/* <div className="rgt-center">
            <p>Hoặc đăng nhập bằng:</p>
          </div>
          <div className="rgt-center">
            <Link href>
              <img src="./img/icon_social/facebook.png" alt="" />
            </Link>
            <Link href>
              <img src="./img/icon_social/google.png" alt="" />
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Register;
