import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
const unidecode = require("unidecode");
function transformToSlug(inputString) {
  const slug = unidecode(inputString)
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return slug;
}
function Header(props) {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  let [showMenu, setShowMenu] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  let changeShowMenu = () => {
    setShowMenu(!showMenu);
  };
  const [ArrayCategories, setArrayCategories] = useState([]);
  const [userName, setUserName] = useState("");
  const [avatar, setAvatar] = useState("/img/avatar.jpg");
  const token = Cookies.get("accessToken");
  const [showSearch, setShowSearch] = useState(false);
  const [recommendProduct, setRecommandProduct] = useState([]);
  useEffect(() => {
    axios.get("/api/products/recommendationsProduct").then((response) => {
      setRecommandProduct(response.data.recommendations);
    });
    axios
      .get("/api/categories/getall")
      .then((res) => {
        let categories = res.data.map((category) => ({
          _id: category._id,
          categoryID: category.categoryID,
          name: transformToSlug(category.name),
          fullName: category.name,
          products: null,
        }));
        const apiPromises = categories.map(async (category) => {
          try {
            const response = await axios.get(
              `api/products/getFollowCategory/${category.name}`
            );
            return response.data.products;
          } catch (error) {
            console.error(
              `Lỗi khi gửi yêu cầu API cho ${category.name}: ${error.message}`
            );
            return null;
          }
        });
        Promise.all(apiPromises)
          .then((listProducts) => {
            categories.forEach((category, index) => {
              category.products = listProducts[index];
            });

            // categories = categories.filter(
            //   (category) => category.products.length >= 10
            // );
            setArrayCategories(categories);
          })
          .catch((error) => {
            console.error("Lỗi khi gửi các yêu cầu API:", error);
          });
      })
      .catch((e) => {});
  }, []);
  useEffect(() => {
    if (token) {
      axios
        .get("/api/users/current", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setUserName(response.data.user.fullName);
          setAvatar("/" + response.data.user.avatar);
        })
        .catch((error) => {
          Cookies.remove("accessToken");
          window.location.reload();
          console.error("Lỗi khi lấy thông tin người dùng:", error);
        });
    }
  }, [token]);
  const handleLogout = () => {
    setShowMenu(false);
    Cookies.remove("accessToken");
    navigate("/dang-nhap");
    window.location.reload();
  };
  const redirectPersonalPage = () => {
    setShowMenu(false);
    navigate("/trang-ca-nhan");
  };
  const [inputValue, setInputValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [moreResult, setMoreResult] = useState(0);
  const [productsSearch, setProductsSearch] = useState([]);
  useEffect(() => {
    clearTimeout(timer);
    if (inputValue) {
      const newTimer = setTimeout(() => {
        doneTyping(inputValue);
      }, 2000);
      setTimer(newTimer);
    } else {
      setProductsSearch([]);
    }
  }, [inputValue]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function doneTyping(value) {
    if (value === inputValue) {
      axios
        .post(`/api/products/searchProduct`, { keyWord: value })
        .then((res) => {
          setProductsSearch(res.data.products);
          setMoreResult(res.data.countResult);
        })
        .catch((error) => {
          console.error("Lỗi:", error);
        });
    }
  }
  const handleSearch = (e) => {
    e.preventDefault();
    inputRef.current.blur();
    setShowSearch(false);
    navigate(`/tim-kiem?s=${encodeURIComponent(inputValue)}`);
  };
  return (
    <>
      <div className="hd-header-infor">
        <div className="hd-address">
          <i className="sharp fa-solid fa-location-dot fa-bounce"></i>
          <p className="hd-address-text">
            198 Đ. Tô Hiến Thành Phường 15 Quận 10 Thành phố Hồ Chí Minh
          </p>
        </div>
        <div className="hd-sign-in-up">
          {userName ? (
            <>
              <p onClick={redirectPersonalPage} className="hd-user-name">
                {userName}
              </p>
              <img src={avatar} alt="" onClick={redirectPersonalPage} />
              <div className="hd-logout" onClick={handleLogout}>
                đăng xuất
              </div>
            </>
          ) : (
            <>
              <Link to="/dang-nhap" className="hd-sign-in">
                Đăng nhập
              </Link>
              <p>/</p>
              <Link to="/dang-ky" className="hd-sign-up">
                Đăng ký
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="hd-header-main">
        <div className="hd-taskbar">
          <Link to="/">
            <img
              src={process.env.PUBLIC_URL + "/img/logo.png"}
              style={{ height: "72px" }}
              alt="Thiết bị y tế giá sỉ chính hãng Lưu Gia"
              className="hd-logo"
            />
          </Link>
          <div className="hd-search-space">
            <div className="hd-main-search">
              <form onSubmit={handleSearch}>
                <input
                  className="hd-search-bar"
                  type="text"
                  name=""
                  id=""
                  placeholder="Tìm tên sản phẩm, dụng cụ y khoa..."
                  ref={inputRef}
                  onFocus={() => {
                    setShowSearchBox(true);
                  }}
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={() => {
                    setTimeout(() => {
                      setShowSearchBox(false);
                    }, 200);
                  }}
                />
              </form>
              <div onClick={handleSearch} className="hd-box-icon">
                <i
                  className="fa-solid fa-magnifying-glass"
                  style={{ color: "#1351dc" }}
                ></i>
              </div>
              {productsSearch.length !== 0 ? (
                <div
                  style={{ display: showSearchBox ? "flex" : "none" }}
                  className="box-product-search"
                >
                  {productsSearch.map((product) => {
                    const keywords = inputValue.split(" ").map((keyword) =>
                      keyword
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    );
                    const productNameWords = product.productName.split(" ");

                    return (
                      <Link
                        to={
                          "/chi-tiet-san-pham/" +
                          transformToSlug(product.productName)
                        }
                        onClick={() => {
                          setShowSearchBox(false);
                          setShowSearch(false);
                        }}
                        className="product-search-row"
                        key={product.sku}
                      >
                        <span className="Highlighter_highlighter">
                          {productNameWords.map((word, index) => (
                            <React.Fragment key={index}>
                              {keywords.includes(
                                word
                                  .toLowerCase()
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                              ) ? (
                                <mark className="Highlighter_mark">{word}</mark>
                              ) : (
                                <span className="Highlighter_span">{word}</span>
                              )}
                              {index < productNameWords.length - 1 && (
                                <span className="Highlighter_span"> </span>
                              )}
                            </React.Fragment>
                          ))}
                        </span>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </Link>
                    );
                  })}
                  {moreResult > 0 ? (
                    <Link
                      to={`/tim-kiem?s=${encodeURIComponent(inputValue)}`}
                      onClick={() => {
                        setShowSearchBox(false);
                      }}
                      className="product-search-row"
                    >
                      <span className="Highlighter_highlighter">
                        <span className="Highlighter_span">
                          Xem thêm {moreResult} kết quả khác
                        </span>
                      </span>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </Link>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="hd-list-keywords-suggest">
              {recommendProduct.length > 0 ? (
                <>
                  {recommendProduct.map((product) => (
                    <div className="hd-keyword-suggest">
                      <Link
                        to={
                          "/chi-tiet-san-pham/" +
                          transformToSlug(product.productName)
                        }
                        onClick={() => {
                          setShowSearchBox(false);
                          setShowSearch(false);
                        }}
                        key={product._id}
                      >
                        {product.productName}
                      </Link>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <Link className="hd-keyword-suggest">đang tải...</Link>
                  <Link className="hd-keyword-suggest">đang tải...</Link>
                  <Link className="hd-keyword-suggest">đang tải...</Link>
                  <Link className="hd-keyword-suggest">đang tải...</Link>
                </>
              )}
            </div>
          </div>
          <div className="hd-box-call">
            <div className="hd-icon-call">
              <img
                src="/img/phone-call.png"
                alt=""
                className="hd-phonecall"
                style={{
                  width: "40px",
                  height: "40px",
                  animation: "shake 0.5s ease-in-out infinite",
                }}
              />
            </div>
            <div className="hd-callnow">
              <div className="hd-text">Tư vấn ngay</div>
              <div className="hd-phone">0939 479 932</div>
            </div>
          </div>
        </div>
        <header className="hd-nav-main">
          <div className="hd-nav">
            <Link className="hd-redirect" to="/">
              <div className="hd-guide">Trang chủ</div>
            </Link>
            <Link className="hd-redirect" to="/danh-muc">
              <div className="hd-guide">Danh mục sản phẩm</div>
            </Link>
            <Link className="hd-redirect" to="/danh-muc/may-do-huyet-ap">
              <div className="hd-guide">Máy đo huyết áp</div>
            </Link>
            <Link className="hd-redirect" to="/danh-muc/may-xong-khi-dung">
              <div className="hd-guide">Máy xông khí dung</div>
            </Link>
            <Link className="hd-redirect" to="/he-thong-cua-hang">
              <div className="hd-guide">Hệ thống cửa hàng</div>
            </Link>
          </div>
          <div className="hd-cart" onClick={() => navigate("/gio-hang")}>
            <div className="hd-icon-cart">
              <i
                className="fa-solid fa-cart-shopping fa-xl"
                style={{ color: "#ffffff" }}
              ></i>
              <span className="hd-cart-items">{props.cartItemCount}</span>
            </div>
            <div className="hd-text-cart">Giỏ hàng</div>
          </div>
        </header>
      </div>
      <header
        className="hd-header-phone"
        style={{ justifyContent: showSearch ? "center" : "space-between" }}
      >
        {showSearch ? (
          <div className="hd-main-search-phone">
            <form onSubmit={handleSearch}>
              <input
                className="hd-search-bar"
                type="text"
                name=""
                id=""
                placeholder="Tìm tên sản phẩm, nhãn hiệu ..."
                autoFocus
                ref={inputRef}
                onFocus={() => {
                  setShowSearchBox(true);
                }}
                value={inputValue}
                onChange={handleInputChange}
              />
            </form>

            <div className="hd-box-icon">
              <i
                className="fa-solid fa-magnifying-glass"
                style={{ color: "#1351dc" }}
              ></i>
            </div>
            <div
              onClick={() => {
                setShowSearch(false);
              }}
              className="hd-close-icon"
            >
              <img
                src="/img/cancel.png"
                style={{ width: "15px", height: "15px" }}
                alt="đóng"
              />
            </div>
          </div>
        ) : (
          <>
            <div className="hd-left">
              <div className="hd-menu" onClick={changeShowMenu}>
                <img src="/img/menu.png" alt="" />
              </div>
              <div
                onClick={() => {
                  navigate("/");
                }}
                className="hd-logo"
              >
                <img
                  src="/img/logo1.png"
                  alt="Thiết bị y tế giá sỉ chính hãng Lưu Gia"
                />
              </div>
            </div>
            <div className="hd-right">
              <div
                onClick={() => {
                  setShowSearch(true);
                }}
                className="hd-search-icon"
              >
                <i
                  className="fa-solid fa-magnifying-glass"
                  style={{ color: "#ffffff" }}
                ></i>
              </div>
              <div className="hd-cart" onClick={() => navigate("/gio-hang")}>
                <div className="hd-icon-cart">
                  <i
                    className="fa-solid fa-cart-shopping fa-xl"
                    style={{ color: "#ffffff" }}
                  ></i>
                  <span className="hd-cart-items">{props.cartItemCount}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </header>
      {showSearch ? (
        showSearchBox ? (
          productsSearch.length > 0 ? (
            <div className="hd-show-search-prd-phone">
              <div>
                <div className="text-recommend">Kết quả tìm kiếm</div>
                {productsSearch.map((prd) => {
                  const keywords = inputValue.split(" ").map((keyword) =>
                    keyword
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  );
                  const productNameWords = prd.productName.split(" ");
                  return (
                    <Link
                      to={
                        "/chi-tiet-san-pham/" + transformToSlug(prd.productName)
                      }
                      onClick={() => {
                        setShowSearchBox(false);
                        setShowSearch(false);
                      }}
                      className="recommend-prd"
                      key={prd._id}
                    >
                      <img src={`${prd.urlImage}`} alt="" className="img-prd" />
                      <div className="name-price-prd-rcm">
                        <span className="Highlighter_highlighter">
                          {productNameWords.map((word, index) => (
                            <React.Fragment key={index}>
                              {keywords.includes(
                                word
                                  .toLowerCase()
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                              ) ? (
                                <mark className="Highlighter_mark">{word}</mark>
                              ) : (
                                <span className="Highlighter_span">{word}</span>
                              )}
                              {index < productNameWords.length - 1 && (
                                <span className="Highlighter_span"> </span>
                              )}
                            </React.Fragment>
                          ))}
                        </span>
                        <p className="price">
                          {prd.sellingPrice.toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </p>
                      </div>
                    </Link>
                  );
                })}
                {moreResult > 0 ? (
                  <Link
                    to={`/tim-kiem?s=${encodeURIComponent(inputValue)}`}
                    onClick={() => {
                      setShowSearchBox(false);
                      setShowSearch(false);
                    }}
                    className="recommend-prd"
                  >
                    <div className="name-price-prd-rcm">
                      <p className="name">Xem thêm {moreResult} kết quả khác</p>
                    </div>
                  </Link>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="hd-show-search-prd-phone">
              <div>
                <div className="text-recommend">Đề xuất hôm nay</div>
                {recommendProduct.map((prd) => (
                  <Link
                    to={
                      "/chi-tiet-san-pham/" + transformToSlug(prd.productName)
                    }
                    onClick={() => {
                      setShowSearchBox(false);
                      setShowSearch(false);
                    }}
                    className="recommend-prd"
                    key={prd._id}
                  >
                    <img src={`${prd.urlImage}`} alt="" className="img-prd" />
                    <div className="name-price-prd-rcm">
                      <p className="name">{prd.productName}</p>
                      <p className="price">
                        {prd.sellingPrice.toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )
        ) : null
      ) : null}

      <div
        className="hd-nav-menu"
        style={{ display: showMenu ? "block" : "none" }}
      >
        <div className="hd-nav">
          <div className="hd-logo-close">
            <img src="/img/logo-footer.png" alt="" />
            <div className="hd-close" onClick={changeShowMenu}>
              <img src="/img/cancel.png" alt="" />
            </div>
          </div>
          <div className="hd-frame-user">
            {userName ? (
              <>
                <div className="hd-user">
                  <img src={avatar} alt="" />
                  <div onClick={redirectPersonalPage} className="hd-infor-user">
                    <p className="hd-name">{userName}</p>
                    <p className="hd-role">Khách hàng</p>
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "5px",
                    cursor: "pointer",
                    padding: "0px 8px",
                    height: "28px",
                    backgroundColor: "#1250DC",
                    borderRadius: "25px",
                    color: "#ffffff",
                    fontSize: "11px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "500",
                  }}
                  onClick={handleLogout}
                  className="hd-logout"
                >
                  Thoát
                </div>
              </>
            ) : (
              <div className="hd-client">
                <p>
                  Đăng nhập để hưởng những đặc quyền dành riêng cho thành viên.
                </p>
                <div className="hd-frame-login-logout">
                  <Link to="/dang-nhap" onClick={changeShowMenu}>
                    <div className="hd-login">Đăng Nhập</div>
                  </Link>
                  <Link to="/dang-ky" onClick={changeShowMenu}>
                    <div className="hd-register">Đăng Ký</div>
                  </Link>
                </div>
              </div>
            )}
          </div>
          {ArrayCategories.length > 0
            ? ArrayCategories.map((category) => (
                <>
                  <Link
                    onClick={changeShowMenu}
                    to={`/danh-muc/${category.name}`}
                  >
                    <div className="hd-category">
                      <p>{category.fullName}</p>
                    </div>
                  </Link>
                </>
              ))
            : null}

          <Link onClick={changeShowMenu} to="/danh-muc">
            <div className="hd-category">
              <p>Danh mục sản phẩm</p>
            </div>
          </Link>
          <Link onClick={changeShowMenu} to="/he-thong-cua-hang">
            <div className="hd-category">
              <p>Hệ thống cửa hàng</p>
            </div>
          </Link>
          <div className="hd-hotline">
            <p className="hd-title">Mọi thắc mắc xin vui lòng liên hệ:</p>
            <div className="hd-phone">
              <i
                className="fa-solid fa-phone fa-shake"
                style={{ color: "#1250dc" }}
              ></i>
              Tư vấn: 0939.479.932 (Miễn phí)
            </div>
          </div>
        </div>
        <div className="hd-mot-nav" onClick={changeShowMenu}></div>
      </div>
      <script src="https://kit.fontawesome.com/b032bb067f.js"></script>
    </>
  );
}

export default Header;
