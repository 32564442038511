import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import axios from "axios";
function OrderHistory() {
  const nav = useNavigate();
  const token = Cookies.get("accessToken");
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    axios
      .get(`/api/invoices/get-invoice?page=${page}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setInvoices(response.data.invoices);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu hóa đơn:", error);
      });
  };
  useEffect(() => {
    handlePageChange(currentPage);
    if (token) {
    } else {
      nav("/");
    }
  }, []);
  return (
    <>
      <div className="orh-container-personal-page">
        <Helmet>
          <title>Lịch Sử Mua Hàng</title>
        </Helmet>
        <div className="orh-menu-bar">
          <Link to="/trang-ca-nhan">
            <div className="orh-item-menu ">
              <img src="./img/information.png" alt="" />
              <p>Tài khoản của bạn</p>
            </div>
          </Link>
          <Link className="active">
            <div className="orh-item-menu black">
              <img src="./img/history.png" alt="" />
              <p>Lịch sử mua hàng</p>
            </div>
          </Link>
          <Link
            onClick={() => {
              Cookies.remove("accessToken");
              nav("/dang-nhap");
              window.location.reload();
            }}
          >
            <div className="orh-item-menu">
              <img src="./img/logout.png" alt="" />
              <p>Đăng xuất</p>
            </div>
          </Link>
        </div>
        <div className="orh-column">
          <div className="orh-frame_infor">
            <div className="orh-header">
              <p>Lịch Sử Mua Hàng</p>
            </div>
            <div className="orh-header-order">
              <p>MÃ ĐƠN HÀNG</p>
              <p>NGÀY ĐẶT HÀNG</p>
              <p>TỔNG TIỀN</p>
              <p>TRẠNG THÁI</p>
              <p>THAO TÁC</p>
            </div>
            {invoices.length > 0 ? (
              <>
                {invoices.map((invoice) => (
                  <div className="orh-header-row" key={invoice._id}>
                    <p>{invoice.code}</p>
                    <p>
                      {format(new Date(invoice.createdAt), "HH:mm dd/MM/yyyy")}
                    </p>
                    <p>
                      {invoice.total.toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })}
                    </p>
                    {/* Kiểm tra trạng thái và hiển thị tương ứng */}
                    <p>
                      {invoice.status.confirmInvoice
                        ? "đã xác nhận đơn hàng"
                        : null}
                      {invoice.status.isShipping ? "đang vận chuyển" : null}
                      {invoice.status.isSuccess ? "giao thành công" : null}
                      {invoice.status.isCancel ? "đã hủy" : null}
                      {invoice.status.confirmInvoice ||
                      invoice.status.isShipping ||
                      invoice.status.isSuccess ||
                      invoice.status.isCancel
                        ? null
                        : "đã đặt"}
                    </p>
                    <div>
                      <Link to={`/chi-tiet-don-hang/${invoice._id}`}>
                        Xem chi tiết
                      </Link>
                    </div>
                  </div>
                ))}
                <div className="orh-header-row">
                  {/* Nút trang trước */}
                  {currentPage == 1 ? (
                    <Link to="#" className="orh-slide-page last-page">
                      <img src="./img/previous.png" alt="Previous" />
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      onClick={() => handlePageChange(currentPage - 1)}
                      className="orh-slide-page"
                    >
                      <img src="./img/previous.png" alt="Previous" />
                    </Link>
                  )}

                  {/* Hiển thị các trang */}
                  {Array.from({ length: totalPages }, (_, index) => {
                    if (
                      totalPages <= 3 ||
                      index + 1 === 1 ||
                      index + 1 === totalPages ||
                      (index + 1 >= currentPage - 1 &&
                        index + 1 <= currentPage + 1)
                    ) {
                      return (
                        <Link
                          key={index + 1}
                          to="#"
                          className={`orh-slide-number ${
                            index + 1 === currentPage ? "active" : "clane"
                          }`}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Link>
                      );
                    }

                    return null;
                  })}
                  {currentPage == totalPages ? (
                    <Link to="#" className="orh-slide-page last-page">
                      <img src="./img/next.png" alt="Next" />
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="orh-slide-page"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <img src="./img/next.png" alt="Next" />
                    </Link>
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "80%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ alignSelf: "center", justifySelf: "center" }}>
                  Không có đơn hàng nào.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="orh-container-personal-page-phone">
        <div className="orh-header-title-infor">
          <p
            onClick={() => {
              nav("/");
            }}
            className="orh-title"
          >
            Trang chủ
          </p>
          <p style={{ padding: "0 5px" }} className="orh-title">
            /
          </p>
          <p style={{ color: "#1250DC" }} className="orh-title">
            Lịch Sử Mua Hàng
          </p>
        </div>
        <div className="orh-row-name-order-phone">
          <div style={{ width: "25%" }} className="orh-header-name-order">
            Mã đơn
          </div>
          <div style={{ width: "30%" }} className="orh-header-name-order">
            Ngày đặt
          </div>
          <div className="orh-header-name-order-total">Tổng tiền</div>
        </div>
        {invoices.length > 0 ? (
          <>
            {invoices.map((invoice) => (
              <div
                onClick={() => {
                  nav(`/chi-tiet-don-hang/${invoice._id}`);
                }}
                className="orh-row-name-order-detail"
                key={invoice._id}
              >
                <div
                  style={{
                    width: "25%",
                    justifyContent: "flex-start",
                    fontSize: "12px",
                  }}
                  className="orh-header-name-detail"
                >
                  {invoice.code}
                </div>
                <div
                  style={{ width: "30%" }}
                  className="orh-header-name-detail"
                >
                  {format(new Date(invoice.createdAt), "dd/MM/yyyy")}
                </div>
                <div
                  style={{ width: "40%" }}
                  className="orh-header-name-detail-total"
                >
                  {invoice.total.toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })}
                </div>
                <div className="orh-header-name-detail-arrow">
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{ color: "#404258" }}
                  ></i>
                </div>
              </div>
            ))}
            <div className="orh-header-row">
              {/* Nút trang trước */}
              {currentPage == 1 ? (
                <Link to="#" className="orh-slide-page last-page">
                  <img src="./img/previous.png" alt="Previous" />
                </Link>
              ) : (
                <Link
                  to="#"
                  onClick={() => handlePageChange(currentPage - 1)}
                  className="orh-slide-page"
                >
                  <img src="./img/previous.png" alt="Previous" />
                </Link>
              )}

              {/* Hiển thị các trang */}
              {Array.from({ length: totalPages }, (_, index) => {
                if (
                  totalPages <= 3 ||
                  index + 1 === 1 ||
                  index + 1 === totalPages ||
                  (index + 1 >= currentPage - 1 && index + 1 <= currentPage + 1)
                ) {
                  return (
                    <Link
                      key={index + 1}
                      to="#"
                      className={`orh-slide-number ${
                        index + 1 === currentPage ? "active" : "clane"
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Link>
                  );
                }

                return null;
              })}
              {currentPage == totalPages ? (
                <Link to="#" className="orh-slide-page last-page">
                  <img src="./img/next.png" alt="Next" />
                </Link>
              ) : (
                <Link
                  to="#"
                  className="orh-slide-page"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <img src="./img/next.png" alt="Next" />
                </Link>
              )}
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
              color: "#404258",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Không có đơn hàng nào.
          </div>
        )}
      </div>
    </>
  );
}

export default OrderHistory;
