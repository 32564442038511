import React from "react";
import { Snackbar, Alert } from "@mui/material";

const SuccessNotification = ({ open, handleClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        Đánh giá thành công!
      </Alert>
    </Snackbar>
  );
};

export default SuccessNotification;
