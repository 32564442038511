import React from "react";

function WarrantyPolicy() {
  return (
    <div className="frame-content-policy">
      <h4>QUY ĐỊNH VỀ CHÍNH SÁCH BẢO HÀNH HỆ THỐNG DỤNG CỤ Y KHOA LƯU GIA</h4>

      <h5>1. Thời hạn bảo hành</h5>
      <p>
        Các sản phẩm có bảo hành theo quy định của Nhà sản xuất sẽ được bảo hành
        theo thời gian quy định trên phiếu bảo hành.
      </p>

      <h5>2. Điều kiện bảo hành</h5>
      <p>Sản phẩm sẽ được bảo hành nếu thoả mãn các điều kiện sau:</p>

      <p>Sản phẩm còn trong hạn bảo hành</p>
      <p>Tem bảo hành phải còn nguyên vẹn, không chấp vá, bôi xóa, sửa chữa.</p>
      <p>Hư hỏng do chất lượng linh kiện hay lỗi quy trình sản xuất.</p>

      <h5>3. Điều kiện từ chối bảo hành</h5>
      <p>
        Sản phẩm thuộc một trong những điều kiện sau sẽ bị từ chối bảo hành:
      </p>

      <p>
        Sản phẩm không có tem/phiếu bảo hành hoặc bị chấp vá, bôi xóa, sửa chữa.
      </p>
      <p>
        Khách hàng tự ý can thiệp vào sản phẩm hoặc sửa chữa tại những trung tâm
        bảo hành không được sự ủy nhiệm của hãng.
      </p>
      <p>
        Sản phẩm hư hỏng do thiên tai, rỉ sét, bể vỡ, bị phá hoại do động
        vật/con người.
      </p>
      <p>Sản phẩm dùng sai điện thế, nguồn điện không ổn định, nguồn yếu.</p>
      <p>
        Sản phẩm hoạt động trong môi trường khắc nghiệt/dơ bẩn không đủ tiêu
        chuẩn hoạt động của thiết bị.
      </p>
      <p>
        Khách hàng gây nên những khuyết tật như biến dạng, rơi vỡ, trầy xước …
      </p>
      <p>
        Sản phẩm được lắp đặt, bảo trì, sử dụng không đúng theo sách hướng dẫn
        của hãng gây ra hư hỏng.
      </p>
      <p>Sản phẩm không phải hàng chính hãng do cửa hàng phân phối.</p>
      <p>Sản phẩm hết hạn bảo hành.</p>

      <h5>
        Tất cả các sản phẩm cần bảo hành vui lòng gửi về công ty, chúng tôi sẽ
        xem xét, nếu sản phẩm có thể xử lý ngay chúng tôi hỗ trợ trực tiếp cho
        khách hàng. Những sản phẩm chúng tôi không thể sửa chữa trực tiếp chúng
        tôi sẽ gửi về hãng để bảo hành.
      </h5>
    </div>
  );
}

export default WarrantyPolicy;
