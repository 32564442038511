import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
function Login() {
  const nav = useNavigate();
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [password, setPasword] = useState("password");
  const [validateLogin, setValidateLogin] = useState("");
  const location = useLocation();
  const data = location.state;
  // Trạng thái để kiểm soát việc hiển thị thông báo
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  // Hàm để tắt thông báo
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  const showSuccessMessageDialog = () => {
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 2000);
  };
  const token = Cookies.get("accessToken");
  useEffect(() => {
    if (token) {
      nav("/");
      window.location.reload();
    }
    if (data && data.successMessage) {
      setEmailValue(data.email);
      setPasswordValue(data.password);
      showSuccessMessageDialog();
    }
  }, []);
  const changeStatePassword = () => {
    password === "password" ? setPasword("text") : setPasword("password");
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post("/api/users/login", {
        email: emailValue,
        password: passwordValue,
      });

      const { accessToken } = response.data;
      Cookies.set("accessToken", accessToken, { expires: 30 });
      nav("/");
      window.location.reload();
    } catch (error) {
      setValidateLogin("Email hoặc mật khẩu không đúng");
      setLoading(false);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <>
      <Helmet>
        <title>Đăng Nhập</title>
      </Helmet>
      <div
        className="lg-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {showSuccessMessage ? (
        <div className="lg-message-dialog">
          <div className="lg-title">
            <h2>Thông báo</h2>
            <button aria-label="close" className="lg-delete modal__button">
              <span
                onClick={closeSuccessMessage}
                className="lg-close-icon"
              ></span>
            </button>
          </div>
          <p>Đăng Ký Thành Công!</p>
          <img src="/img/check.png" />
        </div>
      ) : null}
      <div className="lg-frame-login">
        <div className="lg-login">
          <p className="lg-text">Đăng Nhập</p>
          <input
            type="text"
            name="email"
            id="lg-email"
            placeholder="Email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
          />
          <div className="lg-frm">
            <div className="lg-password">
              <input
                type={password}
                name="password"
                id="lg-password"
                placeholder="Mật khẩu"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              {password === "password" ? (
                <i
                  onClick={changeStatePassword}
                  className="fa-regular fa-eye-slash eye"
                  id="lg-hide-password2"
                  style={{ color: "#827a7a" }}
                />
              ) : (
                <i
                  onClick={changeStatePassword}
                  className="fa-regular fa-eye eye"
                  id="lg-show-password2"
                  style={{ color: "#827a7a" }}
                />
              )}
            </div>
            {validateLogin && <div className="lg-error">{validateLogin}</div>}
          </div>

          <div className="lg-forgetpassword">
            <Link style={{ cursor: "pointer" }} to="/quen-mat-khau">
              Quên mật khẩu?
            </Link>
          </div>
          <button type="submit" onClick={handleLogin}>
            Đăng Nhập
          </button>
          <div className="lg-center">
            <p>Chưa có tài khoản?</p>
            <Link to="/dang-ky" style={{ marginLeft: "5px" }}>
              Đăng ký
            </Link>
          </div>
          {/* <div className="lg-center">
            <p>Hoặc đăng nhập bằng:</p>
          </div>
          <div className="lg-center">
            <Link href>
              <img src="./img/icon_social/facebook.png" alt="" />
            </Link>
            <Link href>
              <img src="./img/icon_social/google.png" alt="" />
            </Link>
          </div> */}
        </div>
      </div>
      <div className="lg-frame-login-phone">
        <div className="lg-login">
          <p className="lg-text">Đăng Nhập</p>
          <input
            type="text"
            name="email"
            id="lg-email"
            placeholder="Email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
          />
          <div className="lg-frm">
            <div className="lg-password">
              <input
                type={password}
                name="password"
                id="lg-password"
                placeholder="Mật khẩu"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              {password === "password" ? (
                <i
                  onClick={changeStatePassword}
                  className="fa-regular fa-eye-slash eye"
                  id="lg-hide-password2"
                  style={{ color: "#827a7a" }}
                />
              ) : (
                <i
                  onClick={changeStatePassword}
                  className="fa-regular fa-eye eye"
                  id="lg-show-password2"
                  style={{ color: "#827a7a" }}
                />
              )}
            </div>
            {validateLogin && <div className="lg-error">{validateLogin}</div>}
          </div>

          <div className="lg-forgetpassword">
            <Link style={{ cursor: "pointer" }} to="/quen-mat-khau">
              Quên mật khẩu?
            </Link>
          </div>
          <button type="submit" onClick={handleLogin}>
            Đăng Nhập
          </button>
          <div className="lg-center">
            <p>Chưa có tài khoản?</p>
            <Link to="/dang-ky" style={{ marginLeft: "5px" }}>
              Đăng ký
            </Link>
          </div>
          {/* <div className="lg-center">
            <p>Hoặc đăng nhập bằng:</p>
          </div>
          <div className="lg-center">
            <Link href>
              <img src="./img/icon_social/facebook.png" alt="" />
            </Link>
            <Link href>
              <img src="./img/icon_social/google.png" alt="" />
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Login;
