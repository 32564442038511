import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

const host = "https://provinces.open-api.vn/api/";

function ChangeAddress() {
  const nav = useNavigate();
  const token = Cookies.get("accessToken");
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [detailAddress, setDetailAddress] = useState("");
  const [selectedCityCode, setSelectedCityCode] = useState(""); // Lưu code của tỉnh/thành phố
  const [selectedCityName, setSelectedCityName] = useState(""); // Lưu tên của tỉnh/thành phố
  const [selectedDistrictCode, setSelectedDistrictCode] = useState(""); // Lưu code của quận/huyện
  const [selectedDistrictName, setSelectedDistrictName] = useState(""); // Lưu tên của quận/huyện
  const [selectedWardCode, setSelectedWardCode] = useState(""); // Lưu code của phường/xã
  const [selectedWardName, setSelectedWardName] = useState(""); // Lưu tên của phường/xã
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [validateAddress, setValidateAddress] = useState("");

  // Fetch danh sách tỉnh/thành phố
  useEffect(() => {
    if (!token) {
      nav("/dang-nhap");
      return;
    }
    callAPI(`${host}?depth=1`, "cities");
  }, [token, nav]);

  // Fetch danh sách quận/huyện khi tỉnh/thành phố được chọn
  useEffect(() => {
    if (selectedCityCode) {
      callAPI(`${host}p/${selectedCityCode}?depth=2`, "districts");
    }
  }, [selectedCityCode]);

  // Fetch danh sách phường/xã khi quận/huyện được chọn
  useEffect(() => {
    if (selectedDistrictCode) {
      callAPI(`${host}d/${selectedDistrictCode}?depth=2`, "wards");
    }
  }, [selectedDistrictCode]);

  // Hàm gọi API
  const callAPI = (api, type) => {
    setLoading(true);
    axios
      .get(api)
      .then((response) => {
        if (type === "cities") {
          setCities(response.data);
        } else if (type === "districts") {
          setDistricts(response.data.districts);
        } else if (type === "wards") {
          setWards(response.data.wards);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  // Xử lý thay đổi tỉnh/thành phố
  const handleCityChange = (event) => {
    const selectedCity = cities.find((city) => city.code == event.target.value);
    if (selectedCity) {
      setSelectedCityCode(selectedCity.code); // Lưu code
      setSelectedCityName(selectedCity.name); // Lưu tên
    }
    setSelectedDistrictCode("");
    setSelectedDistrictName("");
    setSelectedWardCode("");
    setSelectedWardName("");
  };

  // Xử lý thay đổi quận/huyện
  const handleDistrictChange = (event) => {
    const selectedDistrict = districts.find(
      (district) => district.code == event.target.value
    );
    if (selectedDistrict) {
      setSelectedDistrictCode(selectedDistrict.code); // Lưu code
      setSelectedDistrictName(selectedDistrict.name); // Lưu tên
    }
    setSelectedWardCode("");
    setSelectedWardName("");
  };

  // Xử lý thay đổi phường/xã
  const handleWardChange = (event) => {
    const selectedWard = wards.find((ward) => ward.code == event.target.value);
    if (selectedWard) {
      setSelectedWardCode(selectedWard.code); // Lưu code
      setSelectedWardName(selectedWard.name); // Lưu tên
    }
  };

  // Đóng thông báo thành công
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  // Xử lý đăng xuất
  const handleLogout = () => {
    Cookies.remove("accessToken");
    nav("/dang-nhap");
  };

  // Xử lý lưu địa chỉ
  const HandleSaveAddress = () => {
    if (
      !detailAddress ||
      !selectedCityCode ||
      !selectedDistrictCode ||
      !selectedWardCode
    ) {
      setValidateAddress("Vui lòng nhập đầy đủ thông tin địa chỉ.");
      setTimeout(() => setValidateAddress(""), 2500);
      return;
    }

    const address = `${detailAddress}, ${selectedWardName}, ${selectedDistrictName}, ${selectedCityName}.`;
    console.log("Địa chỉ đã chọn:", address); // In ra địa chỉ để kiểm tra
    axios
      .put(
        "/api/users/changeaddress",
        { address },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        setMessage("Thay đổi thông tin cá nhân thành công!");
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          nav("/trang-ca-nhan");
        }, 2000);
      })
      .catch(() => {
        setValidateAddress("Tạm thời không thể thay đổi thông tin, vui lòng thử lại sau!");
        setTimeout(() => setValidateAddress(""), 2500);
      });
  };

  return (
    <>
      {/* Loading spinner */}
      <div
        className="change-address-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
  
      {/* Success message dialog */}
      {showSuccessMessage && (
        <div className="change-address-message-dialog">
          <div className="change-address-title">
            <h2>Thông báo</h2>
            <button
              aria-label="close"
              className="change-address-delete modal__button"
              onClick={closeSuccessMessage}
            >
              <span className="change-address-close-icon"></span>
            </button>
          </div>
          <p>{message}</p>
          <img src="/img/check.png" alt="Success" />
        </div>
      )}
  
      {/* Main content */}
      <div className="change-address-container-personal-page">
        <Helmet>
          <title>Thông Tin Giao Hàng</title>
        </Helmet>
  
        {/* Menu bar */}
        <div className="change-address-menu-bar">
          <a className="change-address-active">
            <div className="change-address-item-menu black">
              <img src="./img/information.png" alt="" />
              <p>Tài khoản của bạn</p>
            </div>
          </a>
          <Link to="/lich-su-mua-hang">
            <div className="change-address-item-menu">
              <img src="./img/history.png" alt="" />
              <p>Lịch sử mua hàng</p>
            </div>
          </Link>
          <a onClick={handleLogout}>
            <div className="change-address-item-menu">
              <img src="./img/logout.png" alt="" />
              <p>Đăng xuất</p>
            </div>
          </a>
        </div>
  
        {/* Address form */}
        <div className="change-address-column">
          <div className="change-address-frame_infor">
            <div className="change-address-header">
              <p>Địa Chỉ Nhận Hàng</p>
            </div>
            <div className="change-address-frame-address">
              <div className="change-address-frame-input">
                <p>Địa chỉ cụ thể</p>
                <input
                  type="text"
                  placeholder="Nhập địa chỉ cụ thể của bạn"
                  value={detailAddress}
                  onChange={(e) => setDetailAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="change-address-center">
              {/* Tỉnh/Thành phố */}
              <div className="change-address-component">
                <p>Tỉnh / Thành Phố</p>
                <select
                  name="city"
                  className="change-address-dropdown"
                  value={selectedCityCode}
                  onChange={handleCityChange}
                >
                  <option value="" disabled>
                    Chọn tỉnh thành
                  </option>
                  {cities.map((city) => (
                    <option key={city.code} value={city.code}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
  
              {/* Quận/Huyện */}
              <div className="change-address-component">
                <p>Quận / Huyện</p>
                <select
                  name="district"
                  className="change-address-dropdown"
                  value={selectedDistrictCode}
                  onChange={handleDistrictChange}
                  disabled={!selectedCityCode}
                >
                  <option value="" disabled>
                    Chọn quận huyện
                  </option>
                  {districts.map((district) => (
                    <option key={district.code} value={district.code}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>
  
              {/* Phường/Xã */}
              <div className="change-address-component">
                <p>Phường / Xã</p>
                <select
                  name="ward"
                  className="change-address-dropdown"
                  value={selectedWardCode}
                  onChange={handleWardChange}
                  disabled={!selectedDistrictCode}
                >
                  <option value="" disabled>
                    Chọn phường xã
                  </option>
                  {wards.map((ward) => (
                    <option key={ward.code} value={ward.code}>
                      {ward.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
  
            {/* Validation message */}
            {validateAddress && (
              <div className="change-address-error">{validateAddress}</div>
            )}
  
            {/* Save button */}
            <div className="change-address-frame-address">
              <button onClick={HandleSaveAddress}>Lưu Thay Đổi</button>
            </div>
          </div>
        </div>
      </div>
  
      {/* Giao diện mobile */}
      <div className="change-address-container-personal-page-mobile">
        <div className="change-address-header-title-infor">
          <p
            onClick={() => nav("/")}
            className="change-address-title"
          >
            Trang chủ
          </p>
          <p style={{ padding: "0 5px" }} className="change-address-title">
            /
          </p>
          <p
            onClick={() => nav("/trang-ca-nhan")}
            className="change-address-title"
          >
            Tài khoản của bạn
          </p>
          <p style={{ padding: "0 5px" }} className="change-address-title">
            /
          </p>
          <p style={{ color: "#1250DC" }} className="change-address-title">
            Thay Đổi Địa Chỉ
          </p>
        </div>
        <div className="change-address-frame_infor">
          <div className="change-address-header">
            <p>Địa Chỉ Nhận Hàng</p>
          </div>
          <div className="change-address-frame-address">
            <div className="change-address-frame-input">
              <p>Địa chỉ cụ thể</p>
              <input
                type="text"
                placeholder="Nhập địa chỉ cụ thể của bạn"
                value={detailAddress}
                onChange={(e) => setDetailAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="change-address-center">
            {/* Tỉnh/Thành phố */}
            <div className="change-address-component">
              <p>Tỉnh / Thành Phố</p>
              <select
                name="city"
                className="change-address-dropdown"
                value={selectedCityCode}
                onChange={handleCityChange}
              >
                <option value="" disabled>
                  Chọn tỉnh thành
                </option>
                {cities.map((city) => (
                  <option key={city.code} value={city.code}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
  
            {/* Quận/Huyện */}
            <div className="change-address-component">
              <p>Quận / Huyện</p>
              <select
                name="district"
                className="change-address-dropdown"
                value={selectedDistrictCode}
                onChange={handleDistrictChange}
                disabled={!selectedCityCode}
              >
                <option value="" disabled>
                  Chọn quận huyện
                </option>
                {districts.map((district) => (
                  <option key={district.code} value={district.code}>
                    {district.name}
                  </option>
                ))}
              </select>
            </div>
  
            {/* Phường/Xã */}
            <div className="change-address-component">
              <p>Phường / Xã</p>
              <select
                name="ward"
                className="change-address-dropdown"
                value={selectedWardCode}
                onChange={handleWardChange}
                disabled={!selectedDistrictCode}
              >
                <option value="" disabled>
                  Chọn phường xã
                </option>
                {wards.map((ward) => (
                  <option key={ward.code} value={ward.code}>
                    {ward.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
  
          {/* Validation message */}
          {validateAddress && (
            <div className="change-address-error">{validateAddress}</div>
          )}
  
          {/* Save button */}
          <div className="change-address-frame-address">
            <button onClick={HandleSaveAddress}>Lưu Thay Đổi</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangeAddress;