import React from "react";

function ShippingPolicy() {
  return (
    <div className="frame-content-policy">
      <h4>Thời gian giao hàng và Chính sách vận chuyển:</h4>
      <p>
        – Sau khi bạn đặt hàng, trong vòng 8 giờ (trừ Lễ, Tết,…) chúng tôi sẽ
        liên lạc lại để kiểm tra thông tin và thỏa thuận thêm những điều khoản
        khác có liên quan. Một số trường hợp khác, chúng tôi sẽ chủ động liên
        lạc với quý khách để thống nhất lại thời gian giao hàng cụ thể.
      </p>
      <p>
        – Trong trường hợp vì là sản phẩm cần gấp cho người bệnh hay các tình
        huống mong mỏi được nhận hàng hóa sớm. Xin quý khách vui lòng thông báo
        cho chúng tôi để biết sắp xếp giao nhận sản phẩm nhanh chóng cho quý
        khách.
      </p>
      <p>– Nội thành Chúng tôi sẽ giao hàng trong ngày.</p>
      <p>
        – Nếu quý khách hàng là các tỉnh thành khác, tùy vào khoảng cách địa lý
        là thành phố trực thuộc trung ương hay thành phố vệ tinh, chúng tôi sẽ
        giao hàng cho quý khách từ 1 đến 7 ngày.
      </p>
      <p>
        – Chúng tôi hỗ trợ giao hàng miễn phí ở các quận quanh bán kinh Công ty
        chúng tôi như Quận 3, quận 5, Quận 10,Quận 11… tùy theo giá trị đơn
        hàng. Liên hệ( gọi điện thoại) để được rõ hơn về miễn phí phí vận
        chuyển.
      </p>
      <p>
        – Những sản phẩm có giá trị nhỏ, xin quý khách hàng đến tại địa chỉ mua
        bán của chúng tôi có ghi trên website để giao dịch mua hàng. Nếu quý
        khách có nhu cầu giao hàng tận nơi xin quý khách vui lòng chi trả chi
        phí cho nhân viên của công ty hoặc các đơn vị vận chuyển theo giá đã
        được thỏa thuận trước.
      </p>
      <p>
        – Những sản phẩm cồng kềnh giao hàng tận nhà quý khách với giá trị lớn,
        xin quý khách liên hệ với chúng tôi để biết thông tin giao nhận hàng hóa
        bằng các hình thức liên hệ như gửi e-mail, điện thoại, gặp trực tiếp xem
        hàng hóa sản phẩm hoặc chat trực tuyến để biết rõ hơn về hàng hóa và vận
        chuyển.
      </p>
      <p>
        – Chúng tôi cam kết giao hàng đúng như trên web .Do đó, những rủi ro
        phát sinh trong quá trình vận chuyển (va đập, ẩm ướt, tai nạn..) có thể
        ảnh hưởng đến hàng hóa, vì thế xin Quý khách vui lòng kiểm tra hàng hóa
        thật kỹ trước khi ký nhận. Chúng tôi sẽ không chịu trách nhiệm với những
        sai lệch hình thức của hàng hoá sau khi Quý khách đã ký nhận hàng.
      </p>
    </div>
  );
}

export default ShippingPolicy;
