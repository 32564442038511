import React from "react";

function InformationSecurityPolicy() {
  return (
    <div className="frame-content-policy">
      <h4>CHÍNH SÁCH BẢO MẬT THÔNG TIN</h4>
      <p>
        – Thu thập thông tin cá nhân được xem là yếu tố cần thiết và bắt buộc
        khi người mua tham gia mua hàng. Hệ Thống Dụng Cụ Y Khoa Lưu Gia cam kết
        bảo mật các thông tin cá nhân của khách hàng (trừ các trường hợp khác
        theo quy định của pháp luật).
      </p>
      <p>
        – Người mua không được tiết lộ với bất cứ bên thứ ba nào về các thông
        tin đã cung cấp cho Hệ Thống Dụng Cụ Y Khoa Lưu Gia. Nếu người mua tiết
        lộ và tạo điều kiện cho bên thứ ba tấn công, gây hại cho hệ thống máy
        chủ của Hệ Thống Dụng Cụ Y Khoa Lưu Gia thì người mua đó phải chịu hoàn
        toàn trách nhiệm.
      </p>
      <h5>1. Mục đích và phạm vi thu thập thông tin:</h5>
      <p>a) Mục đích: việc thu thập thông tin qua website sẽ giúp:</p>
      <p>
        – Nắm bắt được các nguyện vọng, mong muốn của khách hàng nhằm nâng cao
        dịch vụ.
      </p>
      <p>
        – Giúp khách hàng cập nhật các chương trình khuyến mại, giảm giá… do
        chúng tôi tổ chức sớm nhất.
      </p>
      <p>– Hỗ trợ khách hàng khi có khiếu nại, ý kiến một cách nhanh nhất.</p>
      <p> b) Phạm vi thu thập thông tin</p>
      <p>
        Công ty thu thập thông tin khách hàng bao gồm: Họ và tên; Điện thoại;
        Email và Địa chỉ
      </p>
      <h5>2. Phạm vi sử dụng thông tin:</h5>
      <p>
        Chúng tôi rất coi trọng việc bảo mật thông tin khách hàng nên chúng tôi
        cam kết sẽ tuyệt đối không tự ý sử dụng thông tin khách hàng với mục
        đích không mang lại lợi ích cho khách hàng, chúng tôi cam kết không buôn
        bán, trao đổi thông tin bảo mật của khách hàng cho bất cứ bên thứ ba
        nào. Tuy nhiên, trong một số trường hợp đặc biệt sau, chúng tôi có thể
        chia sẻ thông tin khách một cách hợp lý khi:
      </p>
      <p>– Được sự đồng ý của khách hàng.</p>
      <p>
        – Để bảo vệ quyền lợi của chúng tôi và những đối tác: Chúng tôi chỉ đưa
        ra những thông tin cá nhân của khách hàng khi chắc chắn rằng những thông
        tin đó có thể bảo vệ được quyền lợi, tài sản của chúng tôi và những đối
        tác liên quan. Những thông tin này sẽ được tiết lộ một cách hợp pháp
        theo Pháp luật Việt Nam.
      </p>
      <p>
        – Theo yêu cầu của những cơ quan chính phủ khi chúng tôi thấy nó phù hợp
        với pháp luật Việt Nam.
      </p>
      <p>
        – Trong một số trường hợp cần thiết phải cung cấp thông tin khách hàng
        khác, như các chương trình khuyến mãi có sự tài trợ của một bên thứ ba
        chẳng hạn, chúng tôi sẽ thông báo cho quý khách hàng trước khi thông tin
        của quý khách được chia sẻ. Qúy khách có quyền quyết định xem có đồng ý
        chia sẻ thông tin hoặc tham gia hay không.
      </p>
      <h5>3. Thời gian lưu trữ thông tin :</h5>
      <p>
        Chúng tôi sẽ lưu trữ các thông tin cá nhân do Khách hàng cung cấp trên
        các hệ thống nội bộ của chúng tôi trong quá trình cung cấp dịch vụ cho
        Khách hàng hoặc cho đến khi hoàn thành mục đích thu thập hoặc khi Khách
        hàng có yêu cầu hủy các thông tin đã cung cấp.
      </p>
      <h5>4. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân:</h5>
      <h5>Dụng Cụ Y Khoa Lưu Gia</h5>
      <h5>Số 40 Thuận Kiều,Phường 12,Quận 5,Thành Phố Hồ Chí Minh</h5>
      <h5>SDT: 0939 479 932</h5>
      <h5>
        5. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá
        nhân của mình:
      </h5>
      <p>
        Khách hàng có thể thực hiện các quyền trên bằng cách tự truy cập vào
        website hoặc liên hệ với chúng tôi qua email hoặc địa chỉ liên lạc được
        công bố trên website của Công ty.
      </p>
      <h5>6. Cam kết bảo mật thông tin cá nhân khách hàng</h5>
      <p>
        Chúng tôi cam kết bảo đảm an toàn thông tin cho quý khách hàng khi đăng
        ký thông tin cá nhân với Công ty chúng tôi. Chúng tôi cam kết không trao
        đổi mua bán thông tin khách hàng vì mục đích thương mại. Mọi sự chia sẻ
        và sử dụng thông tin khách hàng chúng tôi cam kết thực hiện theo chính
        sách bảo mật của Công ty. Chúng tôi cam kết sẽ khiến quý khách cảm thấy
        tin tưởng và hài lòng về bảo mật thông tin cá nhân khi tham gia và sử
        dụng những dịch vụ của Công ty chúng tôi.
      </p>
      <p>
        Để bảo mật thông tin của khách hàng tốt nhất, chúng tôi khuyến cáo quý
        khách hạn chế truy cập tài khoản bằng đăng nhập tự động, chú ý chế độ
        sao lưu password và đảm bảo đăng xuất khỏi tài khoản khi sử dụng máy
        tính chung để đăng nhập tài khoản trên website của chúng tôi. Chúng tôi
        sẽ không chịu trách nhiệm khi những thông tin cá nhân của quý khách bị
        rò rỉ vì những lý do trên. Chính sách bảo mật chỉ áp dụng những thông
        tin quý khách hàng đăng ký trên website chính thức của Công ty. Mọi
        thông tin quý khách đăng ký tại những wedsite hoặc những địa chỉ khác
        đều không thuộc phạm vi hiệu lực của Chính sách Bảo mật thông tin này.
      </p>
    </div>
  );
}

export default InformationSecurityPolicy;
