import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function Pay(props) {
  const updateCartItemCount = (newCount) => {
    props.updateCartItemCount(newCount);
  };

  const URlSERVER = "/";
  const nav = useNavigate();
  const [loadingToRender, setLoadingToRender] = useState(true);
  const [paySuccess, setPaySuccess] = useState(false);
  const token = Cookies.get("accessToken");
  const [products, setProducts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [detailAddress, setDetailAddress] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedDistrictName, setSelectedDistrictName] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [selectedWardName, setSelectedWardName] = useState("");
  const [validatePhone, setValidatePhone] = useState("");
  const [validateName, setValidateName] = useState("");
  const [validateDetailAddress, setValidateDetailAddress] = useState("");
  const [validateCity, setValidateCity] = useState("");
  const [validateDistrict, setValidateDistrict] = useState("");
  const [validateWard, setValidateWard] = useState("");
  const [invoiceCode, setInvoiceCode] = useState("");

  const host = "https://provinces.open-api.vn/api/";

  // Fetch danh sách tỉnh/thành phố
  useEffect(() => {
    callAPI(`${host}?depth=1`, "cities");
    fetchCartData();
  }, []);

  // Fetch danh sách quận/huyện khi tỉnh/thành phố được chọn
  useEffect(() => {
    if (selectedCity) {
      callAPI(`${host}p/${selectedCity}?depth=2`, "districts");
    }
  }, [selectedCity]);

  // Fetch danh sách phường/xã khi quận/huyện được chọn
  useEffect(() => {
    if (selectedDistrict) {
      callAPI(`${host}d/${selectedDistrict}?depth=2`, "wards");
    }
  }, [selectedDistrict]);

  // Hàm gọi API
  const callAPI = (api, type) => {
    axios
      .get(api)
      .then((response) => {
        if (type === "cities") {
          setCities(response.data);
        } else if (type === "districts") {
          setDistricts(response.data.districts);
        } else if (type === "wards") {
          setWards(response.data.wards);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  // Fetch dữ liệu giỏ hàng
  const fetchCartData = () => {
    if (token) {
      axios
        .get("/api/cart/get-cart", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const productList = response.data.combinedItems;
          setProducts(productList);

          if (productList && productList.length > 0) {
            const cartTotal = productList.reduce((total, item) => {
              const itemTotal =
                item.product.sellingPrice * item.cartItem.quantity;
              return total + itemTotal;
            }, 0);
            setTotalAmount(cartTotal);
            setFullName(response.data.user.fullName);
            setPhone(response.data.user.phoneNumber);
            const userAddress = response.data.user.address;
            if (userAddress.indexOf(",") !== -1) {
              const addressParts = userAddress.split(", ");
              setDetailAddress(addressParts[0].trim());
              setSelectedWard(addressParts[1].trim());
              setSelectedDistrict(addressParts[2].trim());
              setSelectedCity(addressParts[3].slice(0, -1).trim());
              callAPI(`${host}?depth=1`, "cities");
            }
            setLoadingToRender(false);
          }
        })
        .catch((error) => {
          nav("/");
        });
    } else {
      const productIdsInLocalStorage = localStorage.getItem("productIds");
      const listProductIds = JSON.parse(productIdsInLocalStorage).map(
        (product) => product.productId
      );
      if (JSON.parse(productIdsInLocalStorage).length > 0) {
        axios
          .post(
            "/api/products/get-list-product-in-cart",
            { productIds: listProductIds },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const products = response.data;
            const combinedItems = JSON.parse(productIdsInLocalStorage).map(
              (productIdInLocalStorage) => {
                const matchingProduct = products.find(
                  (product) => product.sku === productIdInLocalStorage.productId
                );

                if (matchingProduct) {
                  return {
                    cartItem: {
                      productId: productIdInLocalStorage.productId,
                      quantity: productIdInLocalStorage.quantity,
                    },
                    product: matchingProduct,
                  };
                }
              }
            );
            const productList = combinedItems;
            setProducts(productList);

            if (productList && productList.length > 0) {
              const cartTotal = productList.reduce((total, item) => {
                const itemTotal =
                  item.product.sellingPrice * item.cartItem.quantity;
                return total + itemTotal;
              }, 0);

              setTotalAmount(cartTotal);
              setLoadingToRender(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        nav("/");
      }
    }
  };

  // Xử lý thay đổi tỉnh/thành phố
  const handleCityChange = (event) => {
    const selectedCity = cities.find((city) => city.code == event.target.value);
    if (selectedCity) {
      setSelectedCity(selectedCity.code); // Lưu code
      setSelectedCityName(selectedCity.name); // Lưu tên
    }
    setSelectedDistrict("");
    setSelectedDistrictName("");
    setSelectedWard("");
    setSelectedWardName("");
  };

  // Xử lý thay đổi quận/huyện
  const handleDistrictChange = (event) => {
    const selectedDistrict = districts.find(
      (district) => district.code == event.target.value
    );
    if (selectedDistrict) {
      setSelectedDistrict(selectedDistrict.code); // Lưu code
      setSelectedDistrictName(selectedDistrict.name); // Lưu tên
    }
    setSelectedWard("");
    setSelectedWardName("");
  };

  // Xử lý thay đổi phường/xã
  const handleWardChange = (event) => {
    const selectedWard = wards.find((ward) => ward.code == event.target.value);
    if (selectedWard) {
      setSelectedWard(selectedWard.code); // Lưu code
      setSelectedWardName(selectedWard.name); // Lưu tên
    }
  };

  // Xử lý thay đổi số điện thoại
  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedInput = inputValue.replace(/[^0-9]/g, "").slice(0, 10);
    setPhone(sanitizedInput);
  };

  // Xử lý thanh toán
  const PayOrder = () => {
    let validate = false;
    if (fullName.length < 1) {
      setValidateName("Tên không được để trống.");
      setTimeout(() => setValidateName(""), 2500);
      validate = true;
    }
    if (fullName.length > 25) {
      setValidateName("Tên không được quá 25 ký tự.");
      setTimeout(() => setValidateName(""), 2500);
      validate = true;
    }
    if (phone.length < 1) {
      setValidatePhone("Số điện thoại không được để trống.");
      setTimeout(() => setValidatePhone(""), 2500);
      validate = true;
    }

    const vietnamesePhoneNumberRegex = /^(0[1-9][0-9]{8,9})$/;
    if (!vietnamesePhoneNumberRegex.test(phone)) {
      setValidatePhone("Số điện thoại không hợp lệ.");
      setTimeout(() => setValidatePhone(""), 2500);
      validate = true;
    }
    if (!detailAddress) {
      setValidateDetailAddress("Bạn chưa điền địa chỉ nhận hàng.");
      setTimeout(() => {
        setValidateDetailAddress("");
      }, 2500);
      validate = true;
    }
    if (!selectedCity) {
      setValidateCity("Bạn chưa chọn tỉnh/thành phố");
      setTimeout(() => {
        setValidateCity("");
      }, 2500);
      validate = true;
    }
    if (!selectedDistrict) {
      setValidateDistrict("Bạn chưa chọn quận/huyện");
      setTimeout(() => {
        setValidateDistrict("");
      }, 2500);
      validate = true;
    }
    if (!selectedWard) {
      setValidateWard("bạn chưa chọn phường/xã");
      setTimeout(() => {
        setValidateWard("");
      }, 2500);
      validate = true;
    }
    if (validate) {
      return;
    }
    if (token) {
      setLoading(true);
      axios
        .post(
          "/api/invoices/passenger-create",
          {
            fullName,
            shippingAddress:
              detailAddress +
              ", " +
              selectedWardName +
              ", " +
              selectedDistrictName +
              ", " +
              selectedCityName +
              ".",
            shippingPhone: phone,
            total: totalAmount,
            listProduct: products.map((item) => ({
              sku: item.product.sku,
              sellingPrice: item.product.sellingPrice,
              quantity: item.cartItem.quantity,
            })),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setInvoiceCode(response.data.invoiceCode);
          setLoading(false);
          updateCartItemCount(0);
          setPaySuccess(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      axios
        .post("/api/invoices/local-user-create", {
          fullName,
          shippingAddress:
            detailAddress +
            ", " +
            selectedWardName +
            ", " +
            selectedDistrictName +
            ", " +
            selectedCityName +
            ".",
          shippingPhone: phone,
          total: totalAmount,
          listProduct: products.map((item) => ({
            sku: item.product.sku,
            sellingPrice: item.product.sellingPrice,
            quantity: item.cartItem.quantity,
          })),
        })
        .then((response) => {
          setInvoiceCode(response.data.invoiceCode);
          setLoading(false);
          localStorage.removeItem("productIds");
          updateCartItemCount(0);
          setPaySuccess(true);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Thanh Toán</title>
      </Helmet>{" "}
      <div
        className="pg-lds-ring"
        style={{ display: loading ? "flex" : "none" }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {paySuccess ? (
        <div className="pg-pay-success">
          <div className="pg-frame-infor">
            <img src="/img/check.png" />
            <div className="pg-center">Đặt hàng thành công!</div>
            <div className="pg-confirm-info">
              <p>
                <span>Mã đơn hàng : </span>
                {invoiceCode}
              </p>
            </div>{" "}
            <div className="pg-confirm-info">
              <p>
                <span>Người Nhận : </span>
                {fullName}
              </p>
            </div>
            <div className="pg-confirm-info">
              <p>
                <span>Số Điện Thoại : </span>
                {phone}
              </p>
            </div>
            <div className="pg-confirm-info">
              <p>
                <span>Địa Chỉ Nhận Hàng: </span>
                {detailAddress +
                  ", " +
                  selectedWardName +
                  ", " +
                  selectedDistrictName +
                  ", " +
                  selectedCityName +
                  "."}
              </p>
            </div>
            <div className="pg-confirm-info">
              <p>
                <span>Hình Thức Thanh Toán : </span>
                Thanh toán khi nhận hàng.
              </p>
            </div>
            <div className="pg-confirm-info">
              <p>
                <span>Ghi chú : </span>
                {note ? note : "(không có)"}
              </p>
            </div>
            <div className="pg-confirm-info">
              <p>
                <span>Tổng Tiền : </span>
                {totalAmount.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                })}
              </p>
            </div>
            <div className="pg-status-pay">
              <Link to="/">
                <button className="pg-btn-success">Quay Về Trang Chủ</button>
              </Link>
            </div>
          </div>
        </div>
      ) : loadingToRender ? (
        <div
          style={{
            height: "80vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Đang tải ...
        </div>
      ) : (
        <>
          <div className="pg-row-pay">
            <div className="pg-left">
              <div className="pg-header">Thông Tin Đơn Hàng</div>
              <div className="pg-frame-input-name">
                <div className="pg-title">Họ Và Tên:</div>
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                  placeholder="Họ và tên người nhận hàng"
                />
                {validateName ? (
                  <div className="pg-error-infor">{validateName}</div>
                ) : null}
              </div>
              <div className="pg-frame-input-address">
                <div className="pg-title">Địa Chỉ Nhận Hàng:</div>
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Địa chỉ nhận hàng"
                  value={detailAddress}
                  onChange={(e) => {
                    setDetailAddress(e.target.value);
                  }}
                />
                {validateDetailAddress ? (
                  <div className="pg-error-infor">{validateDetailAddress}</div>
                ) : null}
              </div>
              <div className="pg-row-address">
                <div className="pg-frame-input-detail-address">
                  <div className="pg-title">Tỉnh / Thành Phố</div>
                  <select
                    name="city"
                    className="pg-dropdown"
                    value={selectedCity}
                    onChange={handleCityChange}
                  >
                    <option value="" disabled>
                      Chọn tỉnh thành
                    </option>
                    {cities.map((city) => (
                      <option key={city.code} value={city.code}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  {validateCity && (
                    <div className="pg-error-infor">{validateCity}</div>
                  )}
                </div>

                <div className="pg-frame-input-detail-address">
                  <div className="pg-title">Quận / Huyện</div>
                  <select
                    name="district"
                    className="pg-dropdown"
                    value={selectedDistrict}
                    onChange={handleDistrictChange}
                    disabled={!selectedCity}
                  >
                    <option value="" disabled>
                      Chọn quận huyện
                    </option>
                    {districts.map((district) => (
                      <option key={district.code} value={district.code}>
                        {district.name}
                      </option>
                    ))}
                  </select>
                  {validateDistrict && (
                    <div className="pg-error-infor">{validateDistrict}</div>
                  )}
                </div>

                <div className="pg-frame-input-detail-address">
                  <div className="pg-title">Phường / Xã</div>
                  <select
                    name="ward"
                    className="pg-dropdown"
                    value={selectedWard}
                    onChange={handleWardChange}
                    disabled={!selectedDistrict}
                  >
                    <option value="" disabled>
                      Chọn phường xã
                    </option>
                    {wards.map((ward) => (
                      <option key={ward.code} value={ward.code}>
                        {ward.name}
                      </option>
                    ))}
                  </select>
                  {validateWard && (
                    <div className="pg-error-infor">{validateWard}</div>
                  )}
                </div>
              </div>
              <div className="pg-row-email-phone">
                <div className="pg-frame-input-phone">
                  <div className="pg-title">Số Điện Thoại:</div>
                  <input
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    id="phone"
                    placeholder="Số điện thoại người nhận hàng"
                  />
                  {validatePhone ? (
                    <div className="pg-error-infor">{validatePhone}</div>
                  ) : null}
                </div>
              </div>
              <div className="pg-header">Thông Tin Bổ Sung</div>
              <div className="pg-frame-input-note">
                <div className="pg-title">Ghi chú đặt hàng (tùy chọn):</div>
                <input
                  type="text"
                  name="note"
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  id="fullname"
                  placeholder="Ghi chú về đơn đặt hàng của bạn, ví dụ: ghi chú đặc biệt khi giao hàng"
                />
              </div>
            </div>
            <div className="pg-right">
              <div className="pg-header">CHI TIẾT HÓA ĐƠN</div>
              {products.map((product) => (
                <div className="pg-row-product">
                  <div className="pg-product">
                    <img
  src={product.product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.product.urlImage}`}
  alt={product.product.productName}
/>

                    <p className="pg-name">{product.product.productName}</p>
                  </div>
                  <div className="pg-quantity">
                    x{product.cartItem.quantity}
                  </div>
                  <div className="pg-total">
                    {(
                      product.product.sellingPrice * product.cartItem.quantity
                    ).toLocaleString("vi-VN", {
                      style: "currency",
                      currency: "VND",
                    })}
                  </div>
                </div>
              ))}
              <div className="pg-temporary-money">
                <div className="pg-title">Giá tiền tạm tính:</div>
                <div className="pg-money">
                  {totalAmount.toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })}
                </div>
              </div>
              <div className="pg-total-money">
                <div className="pg-title">Thành Tiền:</div>
                <div className="pg-money">
                  {totalAmount.toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })}
                </div>
              </div>
              <div className="pg-header">PHƯƠNG THỨC THANH TOÁN</div>
              <div className="pg-row-method-pay">
                <input
                  type="radio"
                  id="vehicle1"
                  name="vehicle1"
                  defaultValue="COD"
                  checked
                />
                <label htmlFor="vehicle1">COD Trả tiền khi nhận hàng</label>
              </div>
              <div onClick={PayOrder} className="pg-button">
                ĐẶT HÀNG
              </div>
            </div>
          </div>
          <div className="pg-row-pay-phone">
            <div className="pg-header">Thông Tin Đơn Hàng</div>{" "}
            <div className="pg-frame-input-name">
              <div className="pg-title">Họ Và Tên:</div>
              <input
                type="text"
                name="fullname"
                id="fullname"
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                }}
                placeholder="Họ và tên người nhận hàng"
              />
              {validateName ? (
                <div className="pg-error-infor">{validateName}</div>
              ) : null}
            </div>
            <div className="pg-frame-input-address">
              <div className="pg-title">Địa Chỉ Nhận Hàng:</div>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Địa chỉ nhận hàng"
                value={detailAddress}
                onChange={(e) => {
                  setDetailAddress(e.target.value);
                }}
              />
              {validateDetailAddress ? (
                <div className="pg-error-infor">{validateDetailAddress}</div>
              ) : null}
            </div>
            <div className="pg-frame-input-detail-address">
              <div className="pg-title">Tỉnh / Thành Phố</div>
              <select
                name="city"
                className="pg-dropdown"
                value={selectedCity}
                onChange={handleCityChange}
              >
                <option value="" disabled>
                  Chọn tỉnh thành
                </option>
                {cities.map((city) => (
                  <option key={city.code} value={city.code}>
                    {city.name}
                  </option>
                ))}
              </select>
              {validateCity ? (
                <div className="pg-error-infor">{validateCity}</div>
              ) : null}
            </div>
            <div className="pg-frame-input-detail-address">
              <div className="pg-title">Quận / Huyện</div>
              <select
                name="district"
                className="pg-dropdown"
                value={selectedDistrict}
                onChange={handleDistrictChange}
                disabled={!selectedCity}
              >
                <option value="" disabled>
                  Chọn quận huyện
                </option>
                {districts.map((district) => (
                  <option key={district.code} value={district.code}>
                    {district.name}
                  </option>
                ))}
              </select>
              {validateDistrict ? (
                <div className="pg-error-infor">{validateDistrict}</div>
              ) : null}
            </div>
            <div className="pg-frame-input-detail-address">
              <div className="pg-title">Phường / Xã</div>
              <select
                name="ward"
                className="pg-dropdown"
                value={selectedWard}
                onChange={handleWardChange}
                disabled={!selectedDistrict}
              >
                <option value="" disabled>
                  Chọn phường xã
                </option>
                {wards.map((ward) => (
                  <option key={ward.code} value={ward.code}>
                    {ward.name}
                  </option>
                ))}
              </select>
              {validateWard ? (
                <div className="pg-error-infor">{validateWard}</div>
              ) : null}
            </div>{" "}
            <div className="pg-frame-input-phone">
              <div className="pg-title">Số Điện Thoại:</div>
              <input
                type="text"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                id="phone"
                placeholder="Số điện thoại người nhận hàng"
              />
              {validatePhone ? (
                <div className="pg-error-infor">{validatePhone}</div>
              ) : null}
            </div>
            <div className="pg-frame-input-note">
              <div className="pg-title">Ghi chú đặt hàng (tùy chọn):</div>
              <input
                type="text"
                name="note"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                id="fullname"
                placeholder="Ghi chú về đơn đặt hàng của bạn, ..."
              />
            </div>
            <div className="pg-header" style={{ padding: "10px 0" }}>
              CHI TIẾT HÓA ĐƠN
            </div>
            <div className="pg-list-products">
              {products.map((product) => (
                <div className="pg-component-product">
                  <div className="pg-frame-img">
                    <img
  src={product.product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.product.urlImage}`}
  alt={product.product.productName}
/>

                  </div>
                  <div className="pg-frame-name-price-quatity">
                    <p className="pg-name">{product.product.productName}</p>
                    <p className="pg-price">
                      <strong style={{ color: "black", fontWeight: "500" }}>
                        Giá:{" "}
                      </strong>
                      {product.product.sellingPrice.toLocaleString("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      })}
                    </p>
                    <div className="pg-row-quatity">
                      <p className="pg-frame-quatity">
                        <strong>Số lượng: </strong>x{product.cartItem.quantity}
                      </p>
                      <p className="pg-frame-quatity red">
                        <strong>Tổng: </strong>
                        {(
                          product.product.sellingPrice *
                          product.cartItem.quantity
                        ).toLocaleString("vi-VN", {
                          style: "currency",
                          currency: "VND",
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pg-total-money-phone">
              <div className="pg-title">Thành Tiền: </div>
              <div className="pg-money">
                {totalAmount.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                })}
              </div>
            </div>
            <div className="pg-header" style={{ padding: "10px 0" }}>
              PHƯƠNG THỨC THANH TOÁN
            </div>
            <div className="pg-row-method-pay-phone">
              <input
                type="radio"
                id="vehicle1"
                name="vehicle1"
                defaultValue="COD"
                defaultChecked
              />
              <label htmlFor="vehicle1">COD Trả tiền khi nhận hàng</label>
            </div>
            <div className="pg-total-pay">
              <div className="pg-row-total">
                <p className="pg-text">Tổng tiền:</p>{" "}
                <p className="pg-price">
                  {totalAmount.toLocaleString("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  })}
                </p>
              </div>
              <div onClick={PayOrder} className="pg-pay">
                Đặt Hàng
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Pay;
