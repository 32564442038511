import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "antd";
import axios from "axios";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useSwipeable } from "react-swipeable";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
const unidecode = require("unidecode");
function transformToSlug(inputString) {
  const slug = unidecode(inputString)
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return slug;
}
function PageSearch() {
  const queryParameters = new URLSearchParams(window.location.search);
  let [search, setSearch] = useState(queryParameters.get("s"));
  const [searchParams, setSearchParams] = useSearchParams();
  const s = searchParams.get("s");
  useEffect(() => {
    const temps = new URLSearchParams(window.location.search);
    setSearch(temps.get("s"));
  }, [s]);
  const numSkeletons = 20;
  const skeletons = [];
  const skeletonsPhone = [];

  for (let i = 0; i < numSkeletons; i++) {
    skeletons.push(
      <Box sx={{ width: 200, marginRight: 5, my: 2 }} key={i}>
        <Skeleton variant="rectangular" width={200} height={200} />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="80%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" width={60} height={20} />
        </Box>
      </Box>
    );
    skeletonsPhone.push(
      <Box sx={{ width: 130, marginRight: 0, my: 2 }} key={i}>
        <Skeleton variant="rectangular" width={130} height={120} />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="80%" />
          <Skeleton width="60%" />
          <Skeleton variant="rectangular" width={40} height={20} />
        </Box>
      </Box>
    );
  }
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const handlePageChange = async (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(page);
    setLoading(true);
    try {
      const response = await axios.post(
        "/api/products/searchProductWithPagination",
        {
          keyWord: search,
          priceRange: selectedPriceRange,
          categoryId: selectedCategoryId,
          page: page,
        }
      );
      setProducts(response.data.products);
      setTotalProduct(response.data.totalproduct);
      setTotalPages(response.data.pages);
      setLoading(false);
    } catch (error) {
      setProducts([]);
      setTotalProduct(0);
      setTotalPages(0);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/categories/getall")
      .then((res) => {
        setCategories(res.data);
      })
      .catch(() => {});
    axios
      .post("/api/products/searchProductWithPagination", {
        keyWord: search,
        page: 1,
        pageSize: 20,
      })
      .then((response) => {
        setProducts(response.data.products);
        setTotalProduct(response.data.totalproduct);
        setTotalPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setProducts([]);
        setTotalProduct(0);
        setTotalPages(0);
        setLoading(false);
      });
  }, [search]);

  const handleCheckboxChange = async (category) => {
    if (selectedCategoryId === category.categoryID) {
    } else {
      setLoading(true);
      setShowPopUp(false);
      setSelectedCategoryId(category.categoryID);
      try {
        const response = await axios.post(
          "/api/products/searchProductWithPagination",
          {
            keyWord: search,
            priceRange: selectedPriceRange,
            categoryId: category.categoryID,
          }
        );
        setProducts(response.data.products);
        setTotalProduct(response.data.totalproduct);
        setTotalPages(response.data.pages);
        setCurrentPage(1);
        setLoading(false);
      } catch (error) {
        setProducts([]);
        setTotalProduct(0);
        setTotalPages(0);
        setLoading(false);
      }
    }
  };

  const handlePriceRangeChange = async (priceRange) => {
    if (selectedPriceRange === priceRange) {
    } else {
      setLoading(true);
      setShowPopUp(false);
      setSelectedPriceRange(priceRange);
      try {
        const response = await axios.post(
          "/api/products/searchProductWithPagination",
          {
            keyWord: search,
            priceRange: priceRange,
            categoryId: selectedCategoryId,
          }
        );
        setProducts(response.data.products);
        setTotalProduct(response.data.totalproduct);
        setTotalPages(response.data.pages);
        setCurrentPage(1);
        setLoading(false);
      } catch (error) {
        setProducts([]);
        setTotalProduct(0);
        setTotalPages(0);
        setLoading(false);
      }
    }
  };

  const priceRanges = [
    { value: "100000", label: "Dưới 100.000đ" },
    { value: "100000-300000", label: "100.000đ đến 300.000đ" },
    { value: "300000-500000", label: "300.000đ đến 500.000đ" },
    { value: "500000", label: "Trên 500.000đ" },
  ];
  const [showPopUp, setShowPopUp] = useState(false);
  return (
    <div>
      <Helmet>
        <link rel="stylesheet" href="/css/page_search.css" />
        <title>Kết quả tìm kiếm cho {search}</title>
      </Helmet>
      <div className="category-page-phone">
        <div
          className="pop-up-filter"
          style={{ bottom: showPopUp ? "0" : "-50vh" }}
        >
          <div
            style={{
              width: "100%",
              position: "sticky",
              top: "0",
              boxSizing: "border-box",
              padding: "10px 15px 0 15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img
              onClick={() => {
                setShowPopUp(!showPopUp);
              }}
              src="/img/cancel.png"
              style={{ width: "15px", height: "15px" }}
              alt="đóng"
            />
          </div>
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              padding: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "14px", color: "#1e3050", fontWeight: "600" }}
            >
              Loại Sản Phẩm
            </p>
          </div>
          <div className="button-container">
            <button
              className={`button ${selectedCategoryId === "" ? "active" : ""}`}
              onClick={() => handleCheckboxChange({ categoryID: "" })}
            >
              <p>Tất cả</p>{" "}
              {selectedCategoryId === "" ? (
                <div className="check-mark">
                  <i className="fa-solid fa-check"></i>
                </div>
              ) : null}
            </button>
            {categories.length > 0 &&
              categories.map((category) => (
                <button
                  key={category.categoryID}
                  className={`button ${
                    selectedCategoryId === category.categoryID ? "active" : ""
                  }`}
                  onClick={() => handleCheckboxChange(category)}
                >
                  <p>{category.name}</p>
                  {selectedCategoryId === category.categoryID ? (
                    <div className="check-mark">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  ) : null}
                </button>
              ))}
          </div>
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              padding: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{ fontSize: "14px", color: "#1e3050", fontWeight: "600" }}
            >
              Giá Bán
            </p>
          </div>
          <div className="button-container">
            <button
              className={`button ${selectedPriceRange === "" ? "active" : ""}`}
              onClick={() => handlePriceRangeChange("")}
            >
              <p>Tất cả</p>{" "}
              {selectedPriceRange === "" ? (
                <div className="check-mark">
                  <i className="fa-solid fa-check"></i>
                </div>
              ) : null}
            </button>
            {priceRanges.map((range) => (
              <button
                key={range.value}
                className={`button ${
                  selectedPriceRange === range.value ? "active" : ""
                }`}
                onClick={() => handlePriceRangeChange(range.value)}
              >
                <p>{range.label}</p>
                {selectedPriceRange === range.value ? (
                  <div className="check-mark">
                    <i className="fa-solid fa-check"></i>
                  </div>
                ) : null}
              </button>
            ))}
          </div>
        </div>
        <div className="header-filter-phone">
          <div className="left">
            <div
              onClick={() => {
                setShowPopUp(!showPopUp);
              }}
              className="filter"
            >
              <p>Lọc</p>
              <img src="/img/filter.png" alt="" />
            </div>
          </div>
          <div className="right">
            <p>{totalProduct} kết quả được tìm thấy</p>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            {skeletonsPhone}
          </div>
        ) : (
          <>
            {totalProduct == 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "75vh",
                }}
              >
                <img
                  src="/img/search.png"
                  alt=""
                  style={{ width: 300, height: 300 }}
                />
                <h3
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#4a4f63",
                  }}
                >
                  Không tìm thấy sản phẩm với từ khoá
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#4a4f63",
                    }}
                  >
                    "{search}"
                  </span>
                </h3>
              </div>
            ) : null}
            <div className="home-row-product-phone">
              {products.map((product, index) => (
                <Link
                  to={
                    "/chi-tiet-san-pham/" + transformToSlug(product.productName)
                  }
                  id="redirect-product-phone-1"
                >
                  <div className="home-product-1">
                    {product.sellingPrice !== product.originalPrice
                      ? (() => {
                          const originalPrice = product.originalPrice;
                          const sellingPrice = product.sellingPrice;
                          const discountValue = originalPrice - sellingPrice;
                          const discountPercent =
                            (discountValue / originalPrice) * 100;
                          return (
                            <div className="home-discount">
                              <div className="home-clos">
                                {discountPercent.toFixed(0)}%
                              </div>
                              <div className="home-col-d">
                                <div className="home-left" />
                                <div className="home-right" />
                              </div>
                            </div>
                          );
                        })()
                      : null}

                                   <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                    <h5 className="home-name-product">{product.productName}</h5>
                    <div className="home-price">
                      {product.sellingPrice !== product.originalPrice ? (
                        <>
                          <p className="home-origin-price">
                            {product.originalPrice.toLocaleString("vi-VN", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </p>
                          <p className="home-offcial-price">
                            {product.sellingPrice.toLocaleString("vi-VN", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="home-offcial-price">
                            {product.sellingPrice.toLocaleString("vi-VN", {
                              style: "currency",
                              currency: "VND",
                            })}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="home-star">
                      {Array.from({ length: 5 }, (_, index) =>
                        index < product.averageRating ? (
                          <span className="fa fa-star checked" />
                        ) : (
                          <span className="fa fa-star" />
                        )
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            {totalProduct > 0 ? (
              <div className="orh-header-row">
                {/* Nút trang trước */}
                {currentPage == 1 ? (
                  <a className="orh-slide-page orh-last-page">
                    <img src="/img/previous.png" alt="Previous" />
                  </a>
                ) : (
                  <a
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="orh-slide-page"
                  >
                    <img src="/img/previous.png" alt="Previous" />
                  </a>
                )}

                {/* Hiển thị các trang */}
                {Array.from({ length: totalPages }, (_, index) => {
                  if (
                    totalPages <= 3 ||
                    index + 1 === 1 ||
                    index + 1 === totalPages ||
                    (index + 1 >= currentPage - 1 &&
                      index + 1 <= currentPage + 1)
                  ) {
                    return (
                      <a
                        key={index + 1}
                        className={`orh-slide-number ${
                          index + 1 === currentPage ? "active" : "clane"
                        }`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </a>
                    );
                  }

                  return null;
                })}
                {currentPage == totalPages ? (
                  <a className="orh-slide-page orh-last-page">
                    <img src="/img/next.png" alt="Next" />
                  </a>
                ) : (
                  <a
                    className="orh-slide-page"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <img src="/img/next.png" alt="Next" />
                  </a>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className="page-search-pc">
        <div className="header-filter">
          <div className="left">
            <div className="filter">
              <p>Lọc</p>
              <img src="/img/filter.png" alt="" />
            </div>
          </div>
          <div className="right">
            <p>{totalProduct} kết quả được tìm thấy</p>
          </div>
        </div>
        <div className="row-filter-product">
          <div className="left-filter">
            <div className="all-product">
              <div className="row-header">
                <p>Loại sản phẩm</p>
                <img src="/img/down-arrow.png" alt="" />
              </div>
              <div className="checkbox-product">
                <Checkbox
                  checked={selectedCategoryId === ""}
                  onChange={() => handleCheckboxChange({ categoryID: "" })}
                  style={{ margin: "0" }}
                ></Checkbox>
                <label onClick={() => handleCheckboxChange({ categoryID: "" })}>
                  Tất cả
                </label>
              </div>
              {categories.length > 0
                ? categories.map((category) => {
                    return (
                      <div
                        className="checkbox-product"
                        key={category.categoryID}
                      >
                        <Checkbox
                          checked={selectedCategoryId === category.categoryID}
                          onChange={() => handleCheckboxChange(category)}
                          style={{ margin: "0" }}
                        ></Checkbox>
                        <label
                          onClick={() => handleCheckboxChange(category)}
                          htmlFor={category.name}
                        >
                          {category.name}
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="all-product">
              <div className="row-header">
                <p>Giá bán</p>
                <img src="/img/down-arrow.png" alt="" />
              </div>
              <div className="checkbox-product">
                <Checkbox
                  style={{ margin: "0" }}
                  checked={selectedPriceRange === ""}
                  onChange={() => handlePriceRangeChange("")}
                />
                <label onClick={() => handlePriceRangeChange("")}>Tất cả</label>
              </div>

              {priceRanges.map((range) => (
                <div className="checkbox-product" key={range.value}>
                  <Checkbox
                    style={{ margin: "0" }}
                    checked={selectedPriceRange === range.value}
                    onChange={() => handlePriceRangeChange(range.value)}
                  />
                  <label onClick={() => handlePriceRangeChange(range.value)}>
                    {range.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div
            className="right-product"
            style={{
              alignItems: products.length > 0 ? "flex-start" : "center",
            }}
          >
            {loading ? (
              <div className="row-product">{skeletons}</div>
            ) : (
              <>
                <div
                  className="home-row-product"
                  style={{ justifyContent: "flex-start" }}
                >
                  {products.map((product, index) => (
                    <Link
                      to={
                        "/chi-tiet-san-pham/" +
                        transformToSlug(product.productName)
                      }
                      id="redirect-product1"
                    >
                      <div className="home-product">
                        {product.sellingPrice !== product.originalPrice
                          ? (() => {
                              const originalPrice = product.originalPrice;
                              const sellingPrice = product.sellingPrice;
                              const discountValue =
                                originalPrice - sellingPrice;
                              const discountPercent =
                                (discountValue / originalPrice) * 100;
                              return (
                                <div className="home-discount">
                                  <div className="home-clos">
                                    {discountPercent.toFixed(0)}%
                                  </div>
                                  <div className="home-col-d">
                                    <div className="home-left" />
                                    <div className="home-right" />
                                  </div>
                                </div>
                              );
                            })()
                          : null}

                        <img
  src={product.urlImage.startsWith("https://api.dungcuykhoaluugia.vn/") 
    ? product.urlImage 
    : `https://api.dungcuykhoaluugia.vn/${product.urlImage}`}
  alt=""
/>
                        <h5 className="home-name-product">
                          {product.productName}
                        </h5>
                        <div className="home-price">
                          {product.sellingPrice !== product.originalPrice ? (
                            <>
                              <p className="home-origin-price">
                                {product.originalPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="home-offcial-price">
                                {product.sellingPrice.toLocaleString("vi-VN", {
                                  style: "currency",
                                  currency: "VND",
                                })}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="home-star">
                          {Array.from({ length: 5 }, (_, index) =>
                            index < product.averageRating ? (
                              <span className="fa fa-star checked" />
                            ) : (
                              <span className="fa fa-star" />
                            )
                          )}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                {totalProduct == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/img/search.png"
                      alt=""
                      style={{ width: 300, height: 300 }}
                    />
                    <h3
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#4a4f63",
                      }}
                    >
                      Không tìm thấy sản phẩm với từ khoá
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#4a4f63",
                        }}
                      >
                        "{search}"
                      </span>
                    </h3>
                  </div>
                ) : null}
                {totalProduct != 0 ? (
                  <div className="header-row">
                    {/* Nút trang trước */}
                    {currentPage == 1 ? (
                      <a className="slide-page last-page">
                        <img src="/img/previous.png" alt="" />
                      </a>
                    ) : (
                      <a
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="slide-page"
                      >
                        <img src="/img/previous.png" alt="Previous" />
                      </a>
                    )}

                    {/* Hiển thị các trang */}
                    {Array.from({ length: totalPages }, (_, index) => {
                      if (
                        totalPages <= 3 ||
                        index + 1 === 1 ||
                        index + 1 === totalPages ||
                        (index + 1 >= currentPage - 1 &&
                          index + 1 <= currentPage + 1)
                      ) {
                        return (
                          <a
                            key={index + 1}
                            className={`slide-number ${
                              index + 1 === currentPage ? "active" : "clane"
                            }`}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </a>
                        );
                      }

                      return null;
                    })}
                    {currentPage == totalPages ? (
                      <a className="slide-page last-page">
                        <img src="/img/next.png" alt="Next" />
                      </a>
                    ) : (
                      <a
                        className="slide-page"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <img src="/img/next.png" alt="Next" />
                      </a>
                    )}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
        <div className="trailer ">
          <h1>HỆ THỐNG PHÂN PHỐI DỤNG CỤ Y TẾ CHÍNH HÃNG</h1>
          <h1>LƯU GIA</h1>
        </div>
        <div className="header-brain ">Thương hiệu nổi bật</div>
        <div className="brain-icon ">
          <img
            src="/img/brand_icon/omron.png "
            alt=" "
            className="img-brand-icon "
          />
          <img
            src="/img/brand_icon/beurer.png "
            alt=" "
            className="img-brand-icon "
          />
          <img
            src="/img/brand_icon/microlife.png "
            alt=" "
            className="img-brand-icon "
          />
          <img
            src="/img/brand_icon/omron.png "
            alt=" "
            className="img-brand-icon "
          />
        </div>
      </div>
    </div>
  );
}

export default PageSearch;
// const [min, setMin] = useState(100000);
// const [max, setMax] = useState(150000000);

// const calcLeftPosition = (value) =>
//   ((value - 100000) / (150000000 - 100000)) * 100;

// const handleMinChange = (e) => {
//   const newValue = parseInt(e.target.value);
//   if (newValue > max) return;
//   setMin(newValue);
//   document.getElementById("thumbMin").style.left =
//     calcLeftPosition(newValue) + "%";
//   document.getElementById("min").innerHTML =
//     newValue.toLocaleString("vi-VN") + " đ";
//   document.getElementById("line").style.left =
//     calcLeftPosition(newValue) + "%";
//   document.getElementById("line").style.right =
//     100 - calcLeftPosition(max) + "%";
// };

// const handleMaxChange = (e) => {
//   const newValue = parseInt(e.target.value);
//   if (newValue < min) return;
//   setMax(newValue);
//   document.getElementById("thumbMax").style.left =
//     calcLeftPosition(newValue) + "%";
//   document.getElementById("max").innerHTML =
//     newValue.toLocaleString("vi-VN") + " đ";
//   document.getElementById("line").style.left = calcLeftPosition(min) + "%";
//   document.getElementById("line").style.right =
//     100 - calcLeftPosition(newValue) + "%";
// };
{
  /* <div className="price-product">
            <div className="row-header">
              <p>Giá Sản Phẩm</p>
              <img src="/img/down-arrow.png" alt="" />
            </div>
            <div className="range-slide">
              <div className="slide">
                <div
                  className="line"
                  id="line"
                  style={{ left: "0%", right: "0%" }}
                ></div>
                <span
                  className="thumb"
                  id="thumbMin"
                  style={{ left: "0%" }}
                ></span>
                <span
                  className="thumb"
                  id="thumbMax"
                  style={{ left: "100%" }}
                ></span>
              </div>
              <input
                type="range"
                min="100000"
                max="150000000"
                step="100000"
                value={min}
                onChange={handleMinChange}
                id="rangeMin"
              />
              <input
                type="range"
                min="100000"
                max="150000000"
                step="100000"
                value={max}
                onChange={handleMaxChange}
                id="rangeMax"
              />
            </div>
            <div className="display">
              <p className="text">Giá:</p>
              <p className="price" id="min">
                {min.toLocaleString("vi-VN")} đ
              </p>
              <p className="text-bold">-</p>
              <p className="price" id="max">
                {max.toLocaleString("vi-VN")} đ
              </p>
            </div>
          </div>
          <div className="evaluate-product">
            <div className="row-header">
              <p>Đánh Giá</p>
              <img src="/img/down-arrow.png" alt="" />
            </div>
            <label>
              <Checkbox style={{ margin: "0" }}></Checkbox>
              <div className="star">
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star" />
                <span className="fa fa-star" />
              </div>
              5 sao
            </label>
            <label>
              <Checkbox style={{ margin: "0" }}></Checkbox>
              <div className="star">
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star" />
                <span className="fa fa-star" />
              </div>
              4 sao trở lên
            </label>
            <label>
              <Checkbox style={{ margin: "0" }}></Checkbox>
              <div className="star">
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star" />
                <span className="fa fa-star" />
              </div>
              3 sao trở lên
            </label>
            <label>
              <Checkbox style={{ margin: "0" }}></Checkbox>
              <div className="star">
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star" />
                <span className="fa fa-star" />
              </div>
              2 sao trở lên
            </label>
            <label>
              <Checkbox style={{ margin: "0" }}></Checkbox>
              <div className="star">
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star checked" />
                <span className="fa fa-star" />
                <span className="fa fa-star" />
              </div>
              1 sao trở lên
            </label>
          </div> */
}
{
  /* <div className="sort-by">
            <p>Sắp Xếp Theo:</p>
            <select name="địa chỉ" className="dropdown">
              <option>Mới Nhất</option>
            </select>
          </div> */
}
