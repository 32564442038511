import React from "react";

function ReturnPolicy() {
  return (
    <div className="frame-content-policy">
      <h4>CHÍNH SÁCH ĐỔI/TRẢ HÀNG</h4>
      <h5>1. Điều kiện đổi trả sản phẩm</h5>
      <p>
        - Sản phẩm bị lỗi kỹ thuật được trung tâm bảo hành xác nhận do lỗi của
        nhà cung cấp.
      </p>

      <p>
        - Thời gian đổi hàng không quá 7 ngày kể từ khi khách mua hàng (căn cứ
        theo hóa đơn mua hàng).
      </p>

      <p>- Không bị lỗi về hình thức (trầy, xước, móp méo, ố vàng, vỡ,…)</p>

      <p>- Đầy đủ bao bì, hộp, muốt, sách hướng dẫn, phụ kiện đi kèm,...</p>

      <p>
        - Có đầy đủ các chứng từ kèm theo như: biên nhận, hóa đơn mua hàng,
        phiếu bảo hành, catalogue,...
      </p>

      <p>- Có đầy đủ các linh phụ kiện và quà tặng kèm theo (nếu có),...</p>

      <p>
        Khi đủ các điều kiện nêu trên, Cửa hàng sẽ đổi lại sản phẩm mới cùng
        Model cho khách hàng. Trường hợp sản phẩm cùng Model bị hết hàng chúng
        tôi sẽ tư vấn cho khách hàng Model khác có tính năng và giá bán tương
        đương.
      </p>

      <p>
        Các trường hợp, sản phẩm đã xuất hóa đơn chúng tôi không hỗ trợ đổi trả
        hàng sang model khác hoặc hoàn lại tiền.
      </p>
      <h5>2. Những trường hợp không được đổi trả sản phẩm</h5>
      <p>
        Trong trường hợp không đủ các điều kiện trên, sản phẩm sẽ được bảo hành
        theo quy định của nhà sản xuất.
      </p>
      <p>
        Quý khách vui lòng kiểm tra kỹ hàng hóa và ký nhận tình trạng sản phẩm
        với nhân viên giao nhận khi nhận hàng. Trường hợp phát hiện sản phẩm bị
        lỗi, thiếu phụ kiện,.. quý khách vui lòng liên hệ ngay với bộ phận giao
        nhận để kịp thời xử lý.
      </p>
      <h5>3.Đổi sản phẩm có Size trong ngày</h5>
      <p>
        Hệ Thống Dụng Cụ Y Khoa Lưu Gia luôn tạo điều kiện thuận lợi cho khách
        hàng của mình.Nếu khách hàng mua một sản phẩm có Size
      </p>
      <p>
        Vd: vớ suy giảm tĩnh mach nếu bị nhầm Szie quý khách hàng hãy mang sản
        phẩm còn nguyên vẹn cộng với bill bán hàng sẽ được đổi qua Size khác phù
        hợp…
      </p>
    </div>
  );
}

export default ReturnPolicy;
